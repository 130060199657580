import { Container, Row, Col, Tab } from "react-bootstrap";
import PropTypes from 'prop-types';
import TabsHeaders from "./TabsHeaders";
import UserReportTable from "./UserReportTable";
import CampaignsReportTable from "./CampaignsReportTable";


ReportsTabs.propTypes = {
  users: PropTypes.array,
  territories: PropTypes.array,
  campaigns: PropTypes.array
}

export default function ReportsTabs({ users, territories, campaigns }) {
  return (
      <Tab.Container id="reports-tabs" defaultActiveKey="user-reports">
        <TabsHeaders />
        <Container>
        <Row className="justify-content-start">
          <Col className="">
            <Tab.Content>
              <Tab.Pane eventKey="user-reports">
                <UserReportTable users={users} />
              </Tab.Pane>
              <Tab.Pane eventKey="campaign-reports">
                <CampaignsReportTable territories={territories} campaigns={campaigns} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
        </Container>
      </Tab.Container>
  );

}