import { useEffect, useState, } from "react";
import { Button, Modal, Row, Col, Container, InputGroup, FormControl } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import { db } from '../../firebase';
import { getDocs, collection, query, where, deleteDoc, doc } from "firebase/firestore";
import PropTypes from 'prop-types';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import EditTerritoryModal from "./EditTerritoryModal";

ManageTerritoryModal.propTypes = {
  territories: PropTypes.array,
  showManageTerritoryModal: PropTypes.bool,
  fetchTerritories: PropTypes.func
}

export default function ManageTerritoryModal({ territories, showManageTerritoryModal, fetchTerritories }) {
  const [show, setShow] = useState(showManageTerritoryModal);
  const [ter, setTer] = useState(territories);
  const handleClose = () => setShow(false);
  // query(collection(db, "territories"), where('territoryname', '>=', 'Ham'), where('territoryname', '<=', 'ham' + '~'));
  useEffect(() => {
    fetchTerritories();
  }, []);

  const deleteTerritories = async (id) => {
    await deleteDoc(doc(db, "territories", id))
    .then((async (res) => {
      setTer(await fetchTerritories());
    })).catch((err) => { alert(err) });
  } 

  const searchOnChange = (e) => {
    const debouncedFilter = debounce(async () => {
      const querySnapshot = await getDocs(query(collection(db, "territories"), where('territoryname', '>=', e.target.value), where('territoryname', '<', e.target.value + 'z')));
      let allTerritories = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data();
        allTerritories.push({
          territoryname: doc.data().territoryname,
          id: doc.id,
          paths: doc.data().paths,
          created: doc.data().created,
          assignees: doc.data().assignees
        });
      });
      setTer(allTerritories);
    }, 500)
    debouncedFilter()
  }

  return (
    <>
      <Button onClick={() => setShow(true)} className="btn-success me-1">Manage Territories</Button>
      <Modal centered size="lg" className="manage-territories-modal" show={show} onHide={handleClose}>
        <Container>
          <Modal.Header closeButton className="border-0 flex-column">
            <Modal.Title className="w-100">Manage Territories</Modal.Title>
            <p className="text-black-50 w-100">Edit, Delete or view territory details</p>
          </Modal.Header>
        </Container>
        <Modal.Body>
          <Container>
            <Row>
              <Col><h6>Search Territory</h6> </Col>
            </Row>
            <Row>
              <Col>
                <InputGroup className="mb-2">
                  <FormControl
                    id="search"
                    type="text"
                    placeholder="Enter territory name"
                    aria-label="search"
                    aria-describedby="search"
                    onChange={(e) => {
                      searchOnChange(e)
                    }}
                  />
                </InputGroup>
              </Col>
            </Row>
            <Row>
              <Col><h6>All Territories</h6> </Col>
            </Row>
            <Row>
              <Col className="col-7"><h6>Name</h6> </Col>
              {/* <Col className="col-3"><h6>Assignee</h6> </Col> */}
              <Col className="col-3"><h6>Created</h6> </Col>
            </Row>

            {ter.slice(0, 5).map((x, key) => (
              <Row key={key}>
                <Col className="align-self-center col-7 d-flex">{x.territoryname}</Col>
                {/* <Col className="align-self-center col-3 d-flex">
                    {x.assignees.slice(0, 3).map((assignee, key) => (
                      <img className="rounded manage-territory-user-images" key={key} style={{ position: 'relative' }} src={assignee.profile ? require(assignee.profile) : require('../../assets/png/user0-image.png')} alt={x.fullName} />
                    ))}
                  </Col> */}
                <Col className="align-self-center col-3 d-flex">{x.created}</Col>
                <Col className="align-self-center col-2 d-flex justify-content-around">
                  <EditTerritoryModal territory={x} marker={false} fetchTerritories={fetchTerritories} />
                  <Button onClick={() => deleteTerritories(x.id)} variant="transparent">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M13.7251 6.60245C13.7251 6.50792 13.6477 6.43057 13.5532 6.43057L12.1352 6.43702L9.9997 8.98292L7.8663 6.43917L6.44618 6.43272C6.35165 6.43272 6.27431 6.50792 6.27431 6.6046C6.27431 6.64542 6.28935 6.68409 6.31513 6.71632L9.11024 10.0464L6.31513 13.3743C6.28917 13.4058 6.27476 13.4452 6.27431 13.486C6.27431 13.5806 6.35165 13.6579 6.44618 13.6579L7.8663 13.6515L9.9997 11.1056L12.1331 13.6493L13.5511 13.6558C13.6456 13.6558 13.7229 13.5806 13.7229 13.4839C13.7229 13.4431 13.7079 13.4044 13.6821 13.3722L10.8913 10.0442L13.6864 6.71417C13.7122 6.68409 13.7251 6.64327 13.7251 6.60245Z" fill="#D22F27" />
                      <path d="M10 0.375C4.68477 0.375 0.375 4.68477 0.375 10C0.375 15.3152 4.68477 19.625 10 19.625C15.3152 19.625 19.625 15.3152 19.625 10C19.625 4.68477 15.3152 0.375 10 0.375ZM10 17.9922C5.58711 17.9922 2.00781 14.4129 2.00781 10C2.00781 5.58711 5.58711 2.00781 10 2.00781C14.4129 2.00781 17.9922 5.58711 17.9922 10C17.9922 14.4129 14.4129 17.9922 10 17.9922Z" fill="#D22F27" />
                    </svg>
                  </Button>
                </Col>
              </Row>
            ))
            }
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
}
