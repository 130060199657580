import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Download } from 'react-bootstrap-icons';
import Select from "react-select";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { collection, getDocs, where, query, doc, getDoc } from "firebase/firestore";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { db } from '../../firebase';
import moment from "moment";


const ExportToExcel = ({ apiUserData, fileName, apiReportData }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiUserData, fileName, apiReportData) => {
    const ws = XLSX.utils.json_to_sheet(apiUserData);
    XLSX.utils.sheet_add_aoa(ws, apiReportData, { origin: "A3" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button onClick={(e) => exportToCSV(apiUserData, fileName, apiReportData)} variant="transparent" style={{ color: "#009241" }}><Download className="me-1 text-success" /> Download Report</Button>
  );
};

UserReportTable.propTypes = {
  users: PropTypes.array
}
export default function UserReportTable({ users }) {
  const [selectedUser, setSelectedUser] = useState({});
  const [options, setOptions] = useState([]);
  const [excUserData, setExcUserData] = useState([]);
  const [apiReportData, setApiReportData] = useState([]);
  useEffect(() => {
    let op = [];
    if (users.length > 0) {
      users.forEach((x) => {
        op.push({
          value: JSON.stringify({ 'id': x.id, 'fullName': x.fullName, 'email': x.email, 'avatar': x.avatar, 'phone': x.phone, 'designation': x.designation, 'role': x.role }),
          label: (x.avatar !== '' ? <div><img className="rounded-circle" width={50} height={50} src={x.avatar} /> {x.fullName} {`(${x.email})`}</div> : <div><img width={50} height={50} src="https://firebasestorage.googleapis.com/v0/b/d2d-new-fc399.appspot.com/o/default_user_image.png?alt=media&token=9364927b-e42b-4051-beaa-c253cc36e4e0" /> {x.fullName} {`(${x.email})`}</div>)
        });
      });
      setOptions(op)
    }

  }, [])

  const convertLeadIntToString = (status) => {
    if (status === 1) {
      return 'interested';
    } else if (status === 2) {
      return 'Not Home'
    } else if (status === 3) {
      return 'Come Back'
    } else if (status === 4) {
      return 'Not Interested'
    } else if (status === 5) {
      return 'Empty Property'
    }
    return '';
  }

  const onUserSelect = async (val) => {
    const querySnapshot = await getDocs(query(collection(db, "leads"), where("userId", "==", JSON.parse(val.value).id)));
    let user = {
      fullName: JSON.parse(val.value).fullName,
      id: JSON.parse(val.value).id,
      avatar: JSON.parse(val.value).avatar,
      email: JSON.parse(val.value).email,
      role: JSON.parse(val.value).role,
      designation: JSON.parse(val.value).designation,
      phone: JSON.parse(val.value).phone,
      interested: 0,
      notHome: 0,
      comeBack: 0,
      notInterested: 0,
      emptyProperty: 0
    };
    let repData = [["Territory-Name", "Lead-Status", "Postal-Code", "Pin-Date"]];
    for (const document of querySnapshot.docs) {
      // doc.data() is never undefined for query doc snapshots
      const querySnapshotTer = await getDoc(doc(db, "territories", document.data().territoryId));
      if (querySnapshotTer.data()) {
        repData.push([
          querySnapshotTer.data().territoryname,
          convertLeadIntToString(document.data().status),
          document.data().postalCode,
          moment.unix(document.data().pin_date.seconds).format('DD/MM/yy')
        ]);
        if (document.data().status === 1) {
          user.interested += 1
        } else if (document.data().status === 2) {
          user.notHome += 1
        } else if (document.data().status === 3) {
          user.comeBack += 1
        } else if (document.data().status === 4) {
          user.notInterested += 1
        } else if (document.data().status === 5) {
          user.emptyProperty += 1
        }
      }
    }
    setExcUserData([{
      Name: user.fullName,
      Email: user.email,
      Designation: user.designation,
      Phone: user.phone,
      Role: user.role
    }]);
    setApiReportData(repData);
    setSelectedUser(user);
  }

  return (
    <div>
      <Container fluid className="p-0">
        <Row className="m-4 ms-0">
          <Col sm={6} className="p-0">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select User</Form.Label>
              <Select
                // value={selectedUser}
                onChange={onUserSelect}
                closeMenuOnSelect={true}
                options={options}
                hideSelectedOptions={true}
                isMulti={false}
                isSearchable
              />
              {/* <Form.Select aria-label="Default select example">
                <option>Open this select menu</option>
                <option value="1"><img width={50} height={50} src={require('../../assets/png/auth-bg.png')} />One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select> */}
            </Form.Group>

          </Col>
        </Row>

      </Container>
      <Row className='border rounded'>
        <Col sm={4} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Full Name</Col>
        <Col sm={1} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Interested</Col>
        <Col sm={1} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Not Home</Col>
        <Col sm={1} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Come back</Col>
        <Col sm={2} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Not Interested</Col>
        <Col sm={2} style={{ fontWeight: 'bolder' }} className='pt-3 pb-3'>Empty Property</Col>
      </Row>
      {selectedUser.fullName !== undefined &&
        <Row className='pt-4 pb-4 border rounded'>
          <Col sm={1} className='align-self-center'>
            {selectedUser.avatar !== '' && (
              <img style={{
                height: 50,
                width: 50,
                borderRadius: 100,
              }} src={selectedUser.avatar} alt="avatar" />
            )}
            {selectedUser.avatar === '' && (
              <div className='border'
                style={{
                  height: 43,
                  width: 43,
                  borderRadius: 100,
                  padding: 10,
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  backgroundColor: '#009241'
                }}>
                {`${selectedUser.fullName.split(' ')[0].substring(0, 1)}${selectedUser.fullName.includes(' ') ? selectedUser.fullName.split(' ')[1].substring(0, 1) : ''}`}
              </div>
            )}
          </Col>
          <Col sm={3} className='align-self-center'>
            <p className='m-0'>{`${selectedUser.fullName.split(' ')[0]} ${selectedUser.fullName.includes(' ') ? selectedUser.fullName.split(' ')[1] : ''}`}</p>
            <p className='m-0 text-black-50'>{selectedUser.email}</p>
          </Col>
          <Col sm={1} className='d-flex justify-content-between align-self-center'>
            {selectedUser.interested ? selectedUser.interested : 0}
          </Col>
          <Col sm={1} className='d-flex justify-content-between align-self-center'>
            {selectedUser.notHome ? selectedUser.notHome : 0}
          </Col>
          <Col sm={1} className='d-flex justify-content-between align-self-center'>
            {selectedUser.comeBack ? selectedUser.comeBack : 0}
          </Col>
          <Col sm={2} className='d-flex justify-content-between align-self-center'>
            {selectedUser.notInterested ? selectedUser.notInterested : 0}
          </Col>
          <Col sm={1} className='d-flex justify-content-between align-self-center'>
            {selectedUser.emptyProperty ? selectedUser.emptyProperty : 0}
          </Col>

          <Col sm={2} className='d-flex justify-content-end align-self-center'>
            <ExportToExcel apiReportData={apiReportData} apiUserData={excUserData} fileName={`${selectedUser.fullName}-report`} />
          </Col>
        </Row>
      }
    </div>
  );

}