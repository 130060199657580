import { useState, useEffect } from "react";
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import { getDocs, collection, updateDoc, doc } from "firebase/firestore";
import { db } from '../../firebase';
import PropTypes from 'prop-types';
UpdateUserModal.propTypes = {
  user: PropTypes.object,
  fetchUsers: PropTypes.func,
  currentPage: PropTypes.number
};

export default function UpdateUserModal({ user, fetchUsers, currentPage }) {
  const [show, setShow] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchDesignations = async () => {
    const querySnapshot = await getDocs(collection(db, "company-roles"));
    let allDesignations = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      allDesignations.push({
        id: doc.id,
        designation: doc.data().designation,
      });
      // console.log(doc.id, " => ", doc.data());
    });
    // setSelectedDesignation(allDesignations[allDesignations.findIndex(designation => designation.designation === user.designation)].designation);
    setDesignations(allDesignations);
  }

  useEffect(() => {
    fetchDesignations();
  }, []);

  const updateUser = async (e) => {
    e.preventDefault();
    const name = e.target.fullName.value;
    const role = e.target.role.value;
    const designation = e.target.designation.value;
    const phone = e.target.phone.value;
    await updateDoc(doc(db, "users", user.id), {
      fullName: name,
      role: role,
      designation: designation,
      phone: phone,
    }).then((res) => {
      fetchUsers(currentPage);
      handleClose();
    }).catch((err) => alert(err.message));

  }

  return (
    <>
      <Button variant="transparent" className='me-2' onClick={handleShow}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.75568 15.8906C3.80479 15.8906 3.85389 15.8857 3.903 15.8783L8.03291 15.154C8.08202 15.1442 8.12867 15.1221 8.16304 15.0853L18.5713 4.677C18.5941 4.65428 18.6121 4.6273 18.6244 4.5976C18.6368 4.5679 18.6431 4.53606 18.6431 4.5039C18.6431 4.47174 18.6368 4.4399 18.6244 4.4102C18.6121 4.38049 18.5941 4.35351 18.5713 4.3308L14.4905 0.247536C14.4438 0.200884 14.3825 0.176331 14.3162 0.176331C14.2499 0.176331 14.1885 0.200884 14.1418 0.247536L3.73358 10.6558C3.69675 10.6926 3.67465 10.7368 3.66483 10.7859L2.9405 14.9158C2.91661 15.0474 2.92515 15.1828 2.96536 15.3102C3.00558 15.4377 3.07627 15.5535 3.1713 15.6475C3.33336 15.8047 3.53715 15.8906 3.75568 15.8906ZM5.41059 11.6085L14.3162 2.70535L16.1159 4.50513L7.21037 13.4083L5.02755 13.7937L5.41059 11.6085ZM19.0354 17.9531H0.963937C0.529339 17.9531 0.178223 18.3042 0.178223 18.7388V19.6228C0.178223 19.7308 0.266616 19.8192 0.374651 19.8192H19.6247C19.7327 19.8192 19.8211 19.7308 19.8211 19.6228V18.7388C19.8211 18.3042 19.47 17.9531 19.0354 17.9531Z" fill="#009241" />
        </svg>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-0 flex-column">
          <Modal.Title className="w-100">Edit: {`${user.fullName}`}</Modal.Title>
          <p className="text-black-50 w-100">Enter the correct fields to invite a new user</p>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={updateUser}>
            <Form.Label htmlFor="fullName" as="h6">Full name </Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Text className="bg-transparent" id="fullNameIcon"><i className="text-success bi bi-person"></i></InputGroup.Text>
              <FormControl
                id="fullName"
                name="fullName"
                className="border-start-0"
                placeholder="Enter your fullname"
                aria-label="Fullname"
                aria-describedby="fullname"
                defaultValue={`${user.fullName}`}
              />
            </InputGroup>
            <Form.Label htmlFor="phone" as="h6">Phone</Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Text className="bg-transparent" id="phone-icon"><i className="text-success bi bi-phone"></i></InputGroup.Text>
              <FormControl
                id="phone"
                name="phone"
                className="border-start-0"
                placeholder="03xxxxxxxxx"
                aria-label="phone"
                aria-describedby="phone"
                defaultValue={`${user.phone}`}
              />
            </InputGroup>
            <Form.Label htmlFor='role' as='h6'>Assign Role</Form.Label>
            <InputGroup className='mb-3'>
              <Form.Select
                id='role'
                name="role"
                placeholder='Select role'
                aria-label='role'
                aria-describedby='role'
                required
                defaultValue={user.role}
              >
                <option value="">Select role</option>
                <option value="Admin">Admin</option>
                <option value="User">User</option>
              </Form.Select>
            </InputGroup>
            <Form.Label htmlFor='designation' as='h6'>Assign Designation</Form.Label>
            <InputGroup className='mb-3'>
              <Form.Select
                id='designation'
                name="designation"
                placeholder='Select Designation'
                aria-label='designation'
                aria-describedby='designation'
                required
                defaultValue={user.designation}
              >
                <option value="">Select designation</option>
                {designations.map((x, key) => {
                  return (
                    <option key={key} value={x.designation}>{x.designation}</option>
                  )
                })
                }
              </Form.Select>
            </InputGroup>
            <InputGroup className="mb-2 mt-3 justify-content-between">
              <Button style={{ width: '45%' }} className="rounded" onClick={handleClose} variant="secondary" type="reset">Cancel</Button>
              <Button style={{ width: '45%' }} className="rounded" variant='success' type='submit'>Update User</Button>
            </InputGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
