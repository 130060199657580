import { useEffect, useState } from "react";
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import { db, auth } from '../../firebase';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import { addDoc, getDocs, collection } from "firebase/firestore";
import PropTypes from 'prop-types';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import { notifyCampaignUsers, notifyCreatorAdmin, notifySuperAdmin } from "../../utils/notifications";

CreateTerritoryModal.propTypes = {
  setNotification: PropTypes.func,
  fetchTerritories: PropTypes.func,
  onCreateModalClose: PropTypes.func
}

export default function CreateTerritoryModal({ setNotification, fetchTerritories, onCreateModalClose }) {
  const [show, setShow] = useState(true);
  const [stepOne, setStepOne] = useState(true);
  const [stepTwo, setStepTwo] = useState(false);
  const [allowNext, setAllowNext] = useState(false);
  const [formStates, setFormStates] = useState({
    // assignees: [],
    campaigns: [],
    t_name: ''
  });
  // const [users, setUsers] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const handleClose = () => {
    setShow(false);
    onCreateModalClose();
  }

  useEffect(() => {
    // const fetchUsers = async () => {
    //   const querySnapshot = await getDocs(collection(db, "users"));
    //   let allUsers = [];
    //   querySnapshot.forEach((doc) => {
    //     // doc.data() is never undefined for query doc snapshots
    //     const data = doc.data();
    //     data.id = doc.id;
    //     allUsers.push({
    //       value: data,
    //       label: doc.data().fullName,
    //     });
    //   });
    //   setUsers(allUsers);
    // }

    const fetchCampaigns = async () => {
      const querySnapshot = await getDocs(collection(db, "campaigns"));
      let allCampaigns = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data();
        data.id = doc.id;
        allCampaigns.push({
          value: data,
          label: doc.data().name,
        });
      });
      setCampaigns(allCampaigns);
    }

    // fetchUsers();
    fetchCampaigns();
  }, []);

  const onInputChange = (e) => {
    setFormStates(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  }

  const createTerritory = async e => {
    e.preventDefault()
    const t_name = formStates.t_name;
    // const assigned = formStates.assignees;
    const campaigns = formStates.campaigns;
    const d = new Date()
    // Create a new user with email and password using firebase
    await addDoc(collection(db, "territories"), {
      territoryname: t_name,
      // assignees: assigned,
      campaigns: campaigns,
      setmarker: JSON.parse(localStorage.getItem("current-set-marker")),
      paths: JSON.parse(localStorage.getItem("current-poly-paths")),
      created: String(d.getDate()).padStart(2, '0')+'/'+String(d.getMonth() + 1).padStart(2, '0')+'/'+d.getFullYear(),
      createdBy: auth.currentUser.uid
    }).then(async (res) => {
      fetchTerritories();
      setShow(false);
      await notifySuperAdmin('New Territory Has Been Created', `${t_name} Has Been Created`);
      await notifyCreatorAdmin('New Territory Has Been Created', `${t_name} Has Been Created`);
      if(campaigns.length > 0) {
        for (const camp of campaigns) {
          notifyCampaignUsers(camp, 'Assigned To Campaign', `You Are Assigned To ${camp.name}`);
          notifySuperAdmin('Users Assigned To Campaign', `Admin Assigned Users To ${camp.name}`);
        }
      }
      setNotification(true, t_name);
    }).catch((err) => alert(err.message));
    localStorage.removeItem("current-poly-paths");
    localStorage.removeItem("current-set-marker");
  }

  // const onAssigneeSelect = e => {
  //   var arr = [];
  //   e.forEach((x) => {
  //     arr.push(x.value);
  //   })
  //   // arr.push(JSON.parse(e));
  //   setFormStates(prevState => ({ ...prevState, ['assignees']: arr }));
  // }

  const onCampaignSelect = e => {
    var arr = [];
    e.forEach((x) => {
      arr.push(x.value);
    })
    // arr.push(JSON.parse(e));
    setFormStates(prevState => ({ ...prevState, ['campaigns']: arr }));
  }

  return (
    <>
      <Modal centered show={show} onExit={onCreateModalClose} onHide={handleClose}>
        <Modal.Header closeButton className="border-0 flex-column">
          <Modal.Title className="w-100">Create New Territory</Modal.Title>
          <p className="text-black-50 w-100">Enter details here to create new territory</p>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={createTerritory}>
            {(stepOne && !stepTwo) && (
              <>
                <Form.Label htmlFor="t_name" as="h6">Territory Name</Form.Label>
                <InputGroup className="mb-2">
                  <FormControl
                    id="t_name"
                    name="t_name"
                    defaultValue={(formStates === {} ? "" : formStates.t_name)}
                    type="text"
                    placeholder="E.g. Territory 1"
                    aria-label="t_name"
                    aria-describedby="t_name"
                    onChange={(e) => {
                      onInputChange(e);
                      if(e.target.value.length > 0) {
                        setAllowNext(true);
                      } else {
                        setAllowNext(false);
                      }
                    }}
                  />
                </InputGroup>
              </>
            )}
            {(!stepOne && stepTwo) && (
              <>
                {/* <Form.Label htmlFor='assignee' as='h6'>Assign Territory To</Form.Label>
                <InputGroup className='mb-3'>
                  <MultiSelect
                    onChange={onAssigneeSelect}
                    options={users}
                    jsonValue
                    style={{
                      width: "100%"
                    }}
                  />
                </InputGroup> */}
                <Form.Label htmlFor='assignee' as='h6'>Assign Campaign</Form.Label>
                <InputGroup className='mb-3'>
                  <MultiSelect
                    onChange={onCampaignSelect}
                    options={campaigns}
                    jsonValue
                    style={{
                      width: "100%"
                    }}
                  />
                </InputGroup>
              </>
            )}

            <InputGroup className="mb-2 mt-3 justify-content-between">
              {(stepOne && !stepTwo) && (
                <>
                  <Button style={{ width: '45%' }} className="rounded" onClick={handleClose} variant="secondary" type="reset">Cancel</Button>
                  <Button style={{ width: '45%' }} className="rounded" variant='success' onClick={() => {
                    setStepOne(false);
                    setStepTwo(true);
                  }} disabled={!allowNext} type="button">Next</Button>
                </>
              )}
              {(!stepOne && stepTwo) && (
                <>
                  <Button style={{ width: '45%' }} className="rounded" onClick={() => {
                    setStepOne(true);
                    setStepTwo(false);
                  }} variant="secondary" type="button">Back</Button>
                  <Button style={{ width: '45%' }} className="rounded" variant='success' type="submit">Create</Button>
                </>
              )}
            </InputGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
