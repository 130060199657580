import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import { useJsApiLoader } from '@react-google-maps/api';
import AuthLayout from './layouts/auth/AuthLayout';
import DashboardLayout from './layouts/dashboard/DashboardLayout';
import Auth from './pages/authentication/Auth';
import EmailVerification from './pages/email-verification/EmailVerification';
import Territory from './pages/territory/Territory';
import Todo from './pages/todo/Todo';
import Users from './pages/users/Users';
import Campaigns from './pages/campaigns/Campaigns';
import Configuration from './pages/configuration/Configuration';
import Overview from './pages/overview/Overview';
import Reports from './pages/reports/Reports';
//
// import { isLoggedIn, isLoggingOut } from './utils/APIRequests';

export default function Router() {
  const [loggedIn, setLoggedIn] = React.useState(localStorage.getItem('token') ? true : false);
  const [libraries] = React.useState(['drawing']);
  
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCL21ZpWZTmzm2rFIMY4M9FXVK6lptV5vo", // ,
    libraries,
    id: "map"
    // ...otherOptions
  });

  const logout = () => {
    localStorage.removeItem('token');
    setLoggedIn(false);
    return(
      <Navigate to="/" replace />
    );
  }

  const generateRoutes = () => {
    if (loggedIn) {
     return [
      {
        path: '/dashboard',
        element: <DashboardLayout logout={logout} />,
        children: [
          { path: 'users', element: <Users isLoaded={isLoaded} /> },
          { path: 'todo', element: <Todo /> },
          { path: 'territory', element: <Territory isLoaded={isLoaded} /> },
          { path: 'campaigns', element: <Campaigns /> },
          { path: 'overview', element: <Overview /> },
          { path: 'reports', element: <Reports /> },
          { path: 'configurations', element: <Configuration /> }
        ]
      },
      { path: '*', element: <Navigate to="/dashboard/overview" /> }
    ]
    }
    return [
      {
        path: '/',
        element: <AuthLayout />,
        children: [
          { path: '/', element: <Auth setLoggedIn={setLoggedIn} /> },
          { path: 'verify-email', element: <EmailVerification /> },
          // { path: '*', element: <Navigate to="/404" /> }
        ]
      },
      { path: '*', element: <Navigate to="/" replace /> }
    ];
  };
  return useRoutes(generateRoutes());
}
