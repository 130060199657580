import { useEffect, useState, useMemo } from 'react';
import { Row, Col, Container, Button } from 'react-bootstrap';
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from '../../firebase';
import Pagination from '../../components/pagination/pagination';
// import { users } from '../../assets/mocks';
import UpdateUserModal from './UpdateUserModal';

export default function MemberListings() {
  const [users, setUsers] = useState([]);
  let PageSize = 10;

  const [currentPage, setCurrentPage] = useState(0);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return users.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, users]);

  async function fetchUsers(currentPage = 1) {
    const querySnapshot = await getDocs(collection(db, "users"));
    let allUsers = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      allUsers.push({
        id: doc.id,
        fullName: doc.data().fullName,
        email: doc.data().email,
        avatar: doc.data().avatar !== undefined ? doc.data().avatar : '',
        role: doc.data().role || 'User',
        designation: doc.data().designation || '',
        phone: doc.data().phone || ''

      });
      // console.log(doc.id, " => ", doc.data());
    });
    setCurrentPage(currentPage);
    setUsers(allUsers);
    document.getElementById("user-tabs-tabpane-all-members").setAttribute("style", "height: " + (window.innerHeight - document.getElementById("user-tabs-tabpane-all-members").getBoundingClientRect().top - 70) + "px; overflow-y: scroll; overflow-x: hidden")
  }

  useEffect(() => {

    fetchUsers();
  }, []);

  const deleteUser = async (uid) => {

    await deleteDoc(doc(db, "users", uid))
      .then((res => {
        console.log(res);
        fetchUsers();
      })).catch((err) => { alert(err) });
  }

  const colors = ['#009241', '#7B61FF', '#FCDE00'];
  return (
    <>
      {currentTableData.map((x, key) => {
        return (
          <Row key={key} className='border-bottom user-listing-tables'>
            <Col>
              <Container>
                <Row className='pt-4 pb-4'>
                  <Col sm={1} className='align-self-center'>
                    {x.avatar !== '' && (
                      <div onClick={() => window.open(x.avatar !== '' ? x.avatar : "https://firebasestorage.googleapis.com/v0/b/d2d-new-fc399.appspot.com/o/default_user_image.png?alt=media&token=9364927b-e42b-4051-beaa-c253cc36e4e0", '_blank').focus()}>
                        <img style={{
                          height: 50,
                          width: 50,
                          borderRadius: 100,
                        }} src={x.avatar} alt="avatar" />
                      </div>
                    )}
                    {x.avatar === '' && (
                      <div className='border'
                        style={{
                          height: 50,
                          width: 50,
                          borderRadius: 100,
                          padding: 10,
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          backgroundColor: colors[Math.floor(Math.random(0) * colors.length)]
                        }}>
                        {`${x.fullName.includes(' ') ? x.fullName.split(' ')[0].substring(0, 1) + x.fullName.split(' ')[1].substring(0, 1) : x.fullName.substring(0, 1)}`}
                      </div>
                    )}
                  </Col>
                  <Col sm={3} className='align-self-center'>
                    <p className='m-0'>{`${x.fullName.includes(' ') ? x.fullName.split(' ')[0] + ' ' + x.fullName.split(' ')[1] : x.fullName}`}</p>
                    <p className='m-0 text-black-50'>{x.designation}</p>
                  </Col>
                  <Col sm={4} className='align-self-center'>
                    {x.email}
                  </Col>
                  <Col sm={2} className='align-self-center'>
                    {x.phone}
                  </Col>
                  {localStorage.getItem('superAdminUid') !== x.id && (
                    <Col sm={2} className='align-self-center text-end'>
                      <UpdateUserModal user={x} fetchUsers={fetchUsers} currentPage={currentPage} />
                      <Button onClick={() => deleteUser(x.id)} variant="transparent">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.7251 6.60245C13.7251 6.50792 13.6477 6.43057 13.5532 6.43057L12.1352 6.43702L9.9997 8.98292L7.8663 6.43917L6.44618 6.43272C6.35165 6.43272 6.27431 6.50792 6.27431 6.6046C6.27431 6.64542 6.28935 6.68409 6.31513 6.71632L9.11024 10.0464L6.31513 13.3743C6.28917 13.4058 6.27476 13.4452 6.27431 13.486C6.27431 13.5806 6.35165 13.6579 6.44618 13.6579L7.8663 13.6515L9.9997 11.1056L12.1331 13.6493L13.5511 13.6558C13.6456 13.6558 13.7229 13.5806 13.7229 13.4839C13.7229 13.4431 13.7079 13.4044 13.6821 13.3722L10.8913 10.0442L13.6864 6.71417C13.7122 6.68409 13.7251 6.64327 13.7251 6.60245Z" fill="#D22F27" />
                          <path d="M10 0.375C4.68477 0.375 0.375 4.68477 0.375 10C0.375 15.3152 4.68477 19.625 10 19.625C15.3152 19.625 19.625 15.3152 19.625 10C19.625 4.68477 15.3152 0.375 10 0.375ZM10 17.9922C5.58711 17.9922 2.00781 14.4129 2.00781 10C2.00781 5.58711 5.58711 2.00781 10 2.00781C14.4129 2.00781 17.9922 5.58711 17.9922 10C17.9922 14.4129 14.4129 17.9922 10 17.9922Z" fill="#D22F27" />
                        </svg>
                      </Button>
                    </Col>
                  )}
                </Row>
              </Container>
            </Col>
          </Row>
        );
      }
      )}
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={users.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      />
      {currentTableData.length === 0 &&
        (
          <p className='text-center'>No users Found</p>
        )
      }
    </>
  );
}