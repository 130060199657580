import { useEffect, useState } from "react";
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { db } from '../../firebase';
import { getDocs, query, collection, where, getDoc, doc } from "firebase/firestore";
import PropTypes from 'prop-types';
import moment from "moment";
import 'react-multiple-select-dropdown-lite/dist/index.css';

ViewLeadsModal.propTypes = {
  campaignId: PropTypes.string
}

export default function ViewLeadsModal({ campaignId }) {
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); };
  const handleShow = () => setShow(true);
  const [leads, setLeads] = useState([]);

  const fetchLeads = async () => {
    const querySnapshot = await getDocs(query(collection(db, "leads"), where("campaignId", "==", campaignId)));
    const fetchLeads = [];
    for (const lead of querySnapshot.docs) {
      const userQuerySnapShot = await getDoc(doc(db, 'users', lead.data().userId));
      fetchLeads.push({
        userName: userQuerySnapShot.data().fullName,
        customer_email: lead.data().customer_email ? lead.data().customer_email : '-',
        customer_name: lead.data().customer_name ? lead.data().customer_name : '-',
        customer_phone: lead.data().customer_phone ? lead.data().customer_phone : '-',
        fileUrl: lead.data().fileUrl ? lead.data().fileUrl : '-',
        pin_date: lead.data().pin_date,
        postalCode: lead.data().postalCode,
        status: lead.data().status
      });
    }
    setLeads(fetchLeads);
  }

  useEffect(() => {
    fetchLeads();
  }, []);

  const convertLeadIntToString = (status) => {
    if(status === 1) {
      return 'interested';
    } else if (status === 2) {
      return 'Not Home'
    } else if (status === 3) {
      return 'Come Back'
    } else if (status === 4) {
      return 'Not Interested'
    } else if (status === 5) {
      return 'Empty Property'
    }
    return '';
  }

  return (
    <>
      <Button onClick={handleShow} variant="success" className='me-2 rounded'>
        View Leads
      </Button>
      <Modal centered size="xl" className="view-leads-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0 flex-column">
          <Modal.Title className="w-100">Leads</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="border">
            <Col>User Name</Col>
            <Col>Customer Name</Col>
            <Col>Customer Email</Col>
            <Col>Customer Phone</Col>
            <Col>File Url</Col>
            <Col>Pin</Col>
            <Col>Postal Code</Col>
            <Col>Status</Col>
          </Row>
          {leads.map((x, key) => {
            return (
              <Row className="border" key={key.toString()}>
                <Col>{x.userName}</Col>
                <Col>{x.customer_name}</Col>
                <Col>{x.customer_email}</Col>
                <Col>{x.customer_phone}</Col>
                <Col>{x.fileUrl !== '-' ? (<a href={x.fileUrl}>Veiw File</a>) : ('-')}</Col>
                <Col>{moment.unix(x.pin_date.seconds).format('DD/MM/yy')}</Col>
                <Col>{x.postalCode}</Col>
                <Col>{convertLeadIntToString(x.status)}</Col>
              </Row>
            )
          })}
        </Modal.Body>
      </Modal>
    </>
  );
}
