import axios from 'axios';
import moment from 'moment';
import { collection, addDoc, where, getDoc, query, doc, getDocs } from "firebase/firestore";
import { db, auth } from '../firebase';
var baseUrl = 'https://fcm.googleapis.com/fcm/send';


export default async function sendNotification(data) {
  data.notification.mutable_content = true;
  data.notification.sound = "Tri-tone";

  return await axios.post(baseUrl, data, {
    headers: {
      "Content-Type": "application/json",
      "Authorization":
        "key=AAAA75Qnmgc:APA91bF2UfLwtDfbkM7Ge3jV5u2U2S3Pb6xbrMFgE0J6_nnihWzL9g2GpqqntVD2ddjNtlqO7qs7EacejLXeFIbRhbjSxwG7fhJazirFtyJoqRHMPeJZV_Yz1oz6L-Zy5Ezexs12zwCz"
    }
  }).then(() => {
  }).catch(e => alert('Something went wrong.'));
}

export const insertNotificationIntoCollection = async (uid, title, description) => {
  await addDoc(collection(db, "notifications"), {
    "uid": uid,
    "title": title,
    "description": description,
    "isRead": false,
    "date": moment(new Date()).format("DD-MM-YYYY hh:mm:ss")
  }).then((res) => {
    return true;
  }).catch((err) => alert(err.message));
}

const getSuperAdminFcmToken = async () => {
  if (localStorage.getItem('superAdminFCMToken')) {
    return localStorage.getItem('superAdminFCMToken');
  }
  const superAdmin = await getDocs(query(collection(db, "users"), where("isSuperAdmin", "==", true)));
  if (superAdmin.docs) {
    if (superAdmin.docs[0].data().fcm_token) {
      localStorage.setItem('superAdminFCMToken', superAdmin.docs[0].data().fcm_token);
      return superAdmin.docs[0].data().fcm_token
    }
  }
  return '';
}

export const getSuperAdminUid = async () => {
  if (localStorage.getItem('superAdminUid')) {
    return localStorage.getItem('superAdminUid');
  }
  const superAdmin = await getDocs(query(collection(db, "users"), where("isSuperAdmin", "==", true)));
  if (superAdmin.docs) {
    if (superAdmin.docs[0].id) {
      localStorage.setItem('superAdminUid', superAdmin.docs[0].id);
      return superAdmin.docs[0].id
    }
  }
  return '';
}

const getCurrentUserFcmToken = async () => {
  if (localStorage.getItem(auth.currentUser.uid + 'currentUserFCMToken')) {
    return localStorage.getItem(auth.currentUser.uid + 'currentUserFCMToken');
  }
  const currentUser = await getDoc(doc(db, "users", auth.currentUser.uid));
  if (currentUser && currentUser.data().fcm_token) {
    localStorage.setItem(auth.currentUser.uid + 'currentUserFCMToken', currentUser.data().fcm_token);
    return currentUser.data().fcm_token
  }
  return '';
}

export const notifySuperAdmin = async (title, desciption) => {
  //send fcm notification
  const sAId = await getSuperAdminUid();
  const sFcmToken = await getSuperAdminFcmToken();
  if (sFcmToken) {
    await sendNotification({
      "to": sFcmToken,
      "notification": {
        "title": title,
        "body": desciption,
      }
    });
  }
  if (sAId) {
    await insertNotificationIntoCollection(sAId, title, desciption);
  }
  //add value to collection
}

export const notifyCreatorAdmin = async (title, desciption) => {
  //send fcm notification
  const uFcmToken = await getCurrentUserFcmToken();
  if (uFcmToken) {
    await sendNotification({
      "to": uFcmToken,
      "notification": {
        "title": title,
        "body": desciption,
      }
    });
  }
  if (auth.currentUser.uid) {
    await insertNotificationIntoCollection(auth.currentUser.uid, title, desciption);
  }
  //add value to collection
}

const getFcmTokenByUserId = async (id) => {
  const user = await getDoc(doc(db, "users", id));;
  if (user && user.data().fcm_token) {
    return user.data().fcm_token
  }
  return '';
}

export const notifyCampaignUsers = async (campaign, title, desciption) => {
  for (const userIds of campaign.assignees) {
    const uFcmToken = await getFcmTokenByUserId(userIds);
    if (uFcmToken) {
      await sendNotification({
        "to": uFcmToken,
        "notification": {
          "title": title,
          "body": desciption,
        }
      });
      if (auth.currentUser.uid) {
        await insertNotificationIntoCollection(userIds, title, desciption);
      }
    }
  }
}

export const notifyUserById = async (userId, title, desciption) => {
  const uFcmToken = await getFcmTokenByUserId(userId);
  if (uFcmToken) {
    await sendNotification({
      "to": uFcmToken,
      "notification": {
        "title": title,
        "body": desciption,
      }
    });
  }
  if (userId) {
    await insertNotificationIntoCollection(userId, title, desciption);
  }
}