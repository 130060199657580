import { Container, Row, Col, Tab } from "react-bootstrap";
import PropTypes from 'prop-types';
import TabsHeaders from "./TabsHeaders";
import MemberListings from "./MembersListings";
import DesignationsListings from "./DesignationsListings";
import ApprovedMemberListings from "./ApprovedMembersListings";
import PendingMemberListings from "./PendingMembersListings";
import TrackMembers from "./TrackMembers";

UserTabs.propTypes = {
  fetchDesignations: PropTypes.func,
  setCurrentPage: PropTypes.func,
  designations: PropTypes.array,
  currentPage: PropTypes.number
}

export default function UserTabs({ fetchDesignations, designations, currentPage, setCurrentPage, isLoaded }) {

  return (
      <Tab.Container id="user-tabs" defaultActiveKey="all-members">
        <TabsHeaders />
        <Container fluid>
        <Row className="justify-content-start">
          <Col>
            <Tab.Content>
              <Tab.Pane eventKey="all-members">
                <MemberListings />
              </Tab.Pane>
              <Tab.Pane mountOnEnter unmountOnExit eventKey="approved">
                <ApprovedMemberListings />
              </Tab.Pane>
              <Tab.Pane mountOnEnter unmountOnExit eventKey="Pending">
                <PendingMemberListings />
              </Tab.Pane>
              <Tab.Pane eventKey="role-management">
                <DesignationsListings fetchDesignations={fetchDesignations} designations={designations} currentPage={currentPage} setCurrentPage={setCurrentPage} />
              </Tab.Pane>
              <Tab.Pane eventKey="track-users">
                <TrackMembers isLoaded={isLoaded} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
        </Container>
      </Tab.Container>
  );

}