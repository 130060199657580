import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Toast, ToastContainer } from "react-bootstrap";
import mergeImages from 'merge-images';
import { GoogleMap, DrawingManager, Polygon, Marker, InfoWindow } from '@react-google-maps/api';
import moment from 'moment';
import CreateTerritoryModal from "../../sections/territory/CreateTerritoryModal";
import { auth, db } from '../../firebase';
import { getDocs, collection, query, where } from "firebase/firestore";
import './Territory.page.scss';
import ManageTerritoryModal from "../../sections/territory/ManageTerritoryModal";
import EditTerritoryModal from "../../sections/territory/EditTerritoryModal";

export default function Territory({ isLoaded }) {
  const [zoom, setZoom] = React.useState(15);
  const [drawingMode, setDrawingMode] = React.useState(null);
  const [showCreateModal, setShowCreateModal] = React.useState(false);
  const [territories, setTerritories] = React.useState([]);
  const [paths, setPaths] = React.useState([]);
  const [center, setCenter] = React.useState({ lat: 52.4974751, lng: -2.0040576 });
  const [currentCenter, setCurrentCenter] = React.useState({ lat: 0, lng: 0 });
  const [markerPaths, setMarkerPaths] = useState([]);
  const [userMarkerPaths, setUserMarkerPaths] = useState([]);
  const [showManageTerritoryModal] = useState(false);
  const [showLiveLocations, setShowLiveLocations] = useState(false);
  const [showFilterSection, setShowFilterSection] = useState(false);
  const [state, setState] = useState({});
  const [toastShow, setToastShow] = useState(false);
  const [newTerName, setNewTerName] = useState('');
  const [mapType, setMapType] = useState('roadmap');
  const [newPolygon, setNewPolygon] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  function onMarkerClick(name, value) {
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  const setNotification = (show, name) => {
    setNewTerName(name);
    setToastShow(show);
    setDrawingMode(null);
  }
  const setCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(pos);
          setCurrentCenter(pos);
          setZoom(20);
        },
        error => alert(error)
      );
    }
  }

  const fetchTerritories = async () => {
    let querySnapshot = function(){}
    if(localStorage.getItem('superAdminUid') === localStorage.getItem('currentUserId')) {
      querySnapshot = await getDocs(collection(db, "territories"));
    } else {
      querySnapshot = await getDocs(query(collection(db, "territories"), where('createdBy', '==', localStorage.getItem('currentUserId'))));
    }
    let allTerritories = [];
    let allPaths = [];
    querySnapshot.forEach(async (doc) => {
      // doc.data() is never undefined for query doc snapshots
      if (doc.data().paths && doc.data().paths != null) {
        allTerritories.push({
          id: doc.id,
          territoryname: doc.data().territoryname,
          paths: doc.data().paths ? doc.data().paths : null,
          assignees: doc.data().assignees,
          campaigns: doc.data().campaigns,
          created: doc.data().created
        });
        allPaths.push(doc.data().paths);
        var paths = doc.data().paths;
        var bounds = new window.google.maps.LatLngBounds();
        var i;

        for (i = 0; i < paths.length; i++) {
          bounds.extend(paths[i]);
        }
        var allMarkers = markerPaths;
        // The Center of the Bermuda Triangle - (25.3939245, -72.473816)
        allMarkers.push({
          id: doc.id,
          territoryname: doc.data().territoryname,
          assignees: doc.data().assignees,
          created: doc.data().created,
          campaigns: doc.data().campaigns,
          lat: bounds.getCenter().lat(),
          lng: bounds.getCenter().lng()
        });
        setMarkerPaths(allMarkers);
        for (i = 0; i < allMarkers.length; i++) {
          setState(prevState => ({ ...prevState, ['showEditModal' + i]: false }));
        }
      }

    });
    setPaths(allPaths)
    setTerritories(allTerritories);
    return allTerritories;
    // setShowLiveLocations(false);
  }

  const onSelectedTerritoryChange = (e) => {
    setZoom(0);
    var paths = JSON.parse(e.target.value).paths;
    var bounds = new window.google.maps.LatLngBounds();
    var i;

    for (i = 0; i < paths.length; i++) {
      bounds.extend(paths[i]);
    }
    setCenter({
      lat: bounds.getCenter().lat(),
      lng: bounds.getCenter().lng(),
    });
    setZoom(15);
  }

  const fetchLiveLocations = async (filterType) => {
    const querySnapshot = await getDocs(collection(db, "users"));
    const allUsers = [];
    const offlineUsers = [];
    const onlineUsers = [];
    for (const doc of querySnapshot.docs) {
      // fetch('https://firebasestorage.googleapis.com/v0/b/door-2-door-4decd.appspot.com/o/Avatar.png?alt=media&token=4b340ed8-c41d-4b49-bb7d-a8a0a74c1e21')
      //   .then(res => res.blob()) // Gets the response and returns it as a blob
      //   .then(async blob => {
      //     let objectURL = URL.createObjectURL(blob);
      //     let myImage = new Image();
      //     myImage.src = objectURL;
      //     console.log(objectURL);
      //     await mergeImages([{ src: '/outer-circle.png', x: 0, y: 0 }, { src: '/inner-circle.png', x: 18, y: 18 }, { src: objectURL, x: 33, y: 33 }])
      //     .then(b64 => console.log(b64)) 
      //   });

      if (doc.data().lastLocation) {
        allUsers.push({
          lastLocation: doc.data().lastLocation,
          name: doc.data().fullName,
          lastLogin: doc.data().lastLogin,
          avatar: await mergeImages([{ src: '/outer-circle.png', x: 0, y: 0 }, { src: '/inner-circle.png', x: 18, y: 18 }, { src: '/user0-image.png', x: 35, y: 35 }])
            .then(b64 => b64) // doc.data().avatar !== undefined ? doc.data().avatar : '',
        });
      }

    }
    allUsers.forEach((element) => {
      if (moment(new Date(element.lastLogin)).isAfter(moment(new Date()).subtract(5, 'minutes'))) {
        onlineUsers.push(element);
      } else {
        offlineUsers.push(element);
      }
    });
    if (filterType === 'all') {
      setUserMarkerPaths(allUsers);
    } else if (filterType === 'offline') {
      setUserMarkerPaths(offlineUsers);
    } else if (filterType === 'online') {
      setUserMarkerPaths(onlineUsers);
    } else {
      setUserMarkerPaths([]);
    }

  }

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  useEffect(() => {
    // setCurrentLocation();
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentCenter(pos);
        },
        error => alert(error)
      );
    }
    fetchTerritories();
  }, []);

  const containerStyle = () => {
    var header = document.getElementById("app-header");
    let containerHeight = 0;
    containerHeight = (window.innerHeight - header.offsetHeight) - 2;
    let containerWidth = 100;
    const options = {
      height: `${containerHeight}px`,
      width: `${containerWidth}%`
    }
    return options;
  }


  const onPolygonComplete = polygon => {
    setShowCreateModal(true);
    setNewPolygon(polygon);
    const polyPaths = [];
    for (let i = 0; i < polygon.getPath().getLength(); i++) {
      const xy = polygon.getPath().getAt(i);
      polyPaths.push({
        lat: xy.lat(), lng: xy.lng()
      });
    }
    var bounds = new window.google.maps.LatLngBounds();
    for (var i = 0; i < polygon.getPath().length; i++) {
      bounds.extend(polygon.getPath().getAt(i));
    }
    var centre = bounds.getCenter();

    // var marker = new window.google.maps.Marker({
    //   position: centre,
    //   map: polygon.getMap(),
    //   icon: '/polygon-pencil-icon.svg'
    // });
    localStorage.setItem("current-poly-paths", JSON.stringify(polyPaths));
    localStorage.setItem("current-set-marker", JSON.stringify(centre));
  }

  const polygonRemove = polygon => {
    polygon.setPath([]);
    polygon.setMap(null);
  }
  const onClose = (name) => {
    setState(prevState => ({ ...prevState, [name]: false }));
  }

  const onLoad = drawingManager => {
  }

  const onCreateModalClose = () => {
    setShowCreateModal(false);
    polygonRemove(newPolygon);
  }

  const showusersLiveLocations = () => {
    setMarkerPaths([]);
    if (showLiveLocations) {
      fetchTerritories();
      setShowLiveLocations(false);
    } else {
      setShowLiveLocations(true);
      fetchLiveLocations('all');
    }
  }

  const setFilters = () => {
    const all = document.getElementById("all-user-check").checked;
    const online = document.getElementById("online-check").checked;
    const offline = document.getElementById("offline-check").checked;
    if (all) {
      fetchLiveLocations('all');
    } else if (online) {
      fetchLiveLocations('online');
    } else if (offline) {
      fetchLiveLocations('offline');
    } else {
      fetchLiveLocations('all');
    }
  }

  const onCheckBoxCheck = (e) => {
    if (!e.target.checked) {
      document.getElementById("all-user-check").checked = e.target.checked;
    }
    if (document.getElementById("online-check").checked && document.getElementById("offline-check").checked) {
      document.getElementById("all-user-check").checked = true;
    }
  }

  const onAllCheckBoxCheck = (e) => {
    document.getElementById("online-check").checked = e.target.checked
    document.getElementById("offline-check").checked = e.target.checked
  }

  const divStyle = {
    background: `white`,
    padding: 5,
    borderRadius: 0
  }

  return (
    <Container fluid className="p-0">
      <Row className="m-0 p-0">
        <Col className="p-0">
          {isLoaded && !showLiveLocations && (
            <GoogleMap
              id="map"
              mapTypeId={mapType}
              mapContainerStyle={containerStyle()}
              center={center}
              zoom={zoom}
              options={{
                disableDefaultUI: true,
                styles: [{
                  "featureType": 'poi',
                  "elementType": "labels",
                  "stylers": [{ visibility: 'off' }]
                }]
              }}
            >
              <Marker
                onLoad={() => { }}
                position={currentCenter}

              />
              {drawingMode === null ? (
                <>
                  <Polygon
                    onLoad={onLoad}
                    paths={paths}
                    options={{
                      fillColor: "#E04A26",
                      fillOpacity: 0.3,
                      strokeColor: "#E04A26",
                      strokeOpacity: 1,
                      strokeWeight: 2,
                      clickable: false,
                      draggable: false,
                      editable: false,
                      geodesic: false,
                      zIndex: 1
                    }}
                  />
                  {markerPaths.map((x, key) => (
                    <div key={key}>
                      <Marker
                        key={key}
                        onLoad={() => { }}
                        onClick={() => { onMarkerClick('showEditModal' + key, true) }}
                        icon='/polygon-pencil-icon.png'
                        position={{ lat: x.lat, lng: x.lng }}

                      />
                      {state['showEditModal' + key] && (
                        <EditTerritoryModal territory={x} marker={true} onClose={onClose} name={'showEditModal' + key} fetchTerritories={fetchTerritories} />
                      )}
                    </div>
                  ))
                  }
                </>
              )
                :
                (
                  <DrawingManager
                    onLoad={onLoad}
                    onPolygonComplete={onPolygonComplete}
                    drawingMode={drawingMode}
                    options={{
                      drawingControl: false,
                      drawingControlOptions: {
                        drawingModes: ['polygon']
                      },
                      polygonOptions: {
                        fillColor: "#E04A26",
                        fillOpacity: 0.3,
                        strokeColor: "#E04A26",
                        strokeOpacity: 1,
                        strokeWeight: 2,
                      }
                    }}
                  />
                )}
            </GoogleMap>
          )}
          {isLoaded && showLiveLocations && (
            <GoogleMap
              id="map"
              mapTypeId={mapType}
              mapContainerStyle={containerStyle()}
              center={center}
              zoom={zoom}
              options={{
                disableDefaultUI: true,
                styles: [{
                  "featureType": 'poi',
                  "elementType": "labels",
                  "stylers": [{ visibility: 'off' }]
                }]
              }}
            >
              {userMarkerPaths.map((x, key) => (
                <div key={key}>
                  <Marker
                    onLoad={() => { }}
                    icon={x.avatar}
                    position={{ lat: x.lastLocation.lat, lng: x.lastLocation.lng }}
                    onClick={() => handleActiveMarker(key)}
                  >
                    {activeMarker === key ? (
                      <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div style={divStyle}>
                          <h5>{x.name}</h5>
                          <small>{moment(new Date(x.lastLogin)).fromNow()}</small>
                        </div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                </div>
              ))
              }
            </GoogleMap>
          )}
        </Col>
      </Row>
      <div className="bottom-right d-flex flex-column">
        <button onClick={() => { showusersLiveLocations(); }} className="p-0 btn mb-2">
          <svg width="40" height="40" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_180_16585)">
              <rect style={{ width: '100%', height: "100%" }} rx="8" fill={(showLiveLocations ? '#009241' : 'white')} shapeRendering="crispEdges" />
              <path className="svg-paths" d="M51.1812 62.1813C45.635 60.9025 41.5 55.9338 41.5 50C41.5 43.0963 47.0963 37.5 54 37.5C60.9037 37.5 66.5 43.0963 66.5 50C66.5 55.9338 62.365 60.9025 56.8188 62.1813L54 65L51.1812 62.1813V62.1813ZM47.765 57.8212C48.9297 58.7527 50.2891 59.4102 51.7425 59.745L52.4425 59.9062L54 61.465L55.5587 59.9062L56.2588 59.7438C57.807 59.3868 59.2472 58.6642 60.4587 57.6362C59.6448 56.8007 58.6715 56.1369 57.5964 55.6841C56.5214 55.2313 55.3665 54.9987 54.2 55C51.655 55 49.3625 56.0875 47.765 57.8212V57.8212ZM46.02 56.025C47.0706 54.9101 48.3383 54.0221 49.7452 53.4159C51.152 52.8097 52.6681 52.4979 54.2 52.5C55.6771 52.4981 57.14 52.788 58.5047 53.353C59.8694 53.918 61.1091 54.747 62.1525 55.7925C63.2226 54.2864 63.8545 52.5133 63.9782 50.6699C64.1019 48.8265 63.7124 46.9849 62.8531 45.3493C61.9938 43.7138 60.6981 42.3483 59.11 41.4043C57.5218 40.4603 55.7031 39.9748 53.8558 40.0016C52.0084 40.0283 50.2046 40.5664 48.6444 41.5559C47.0842 42.5455 45.8287 43.948 45.0171 45.6078C44.2055 47.2675 43.8696 49.1196 44.0466 50.9587C44.2237 52.7977 44.9067 54.5518 46.02 56.0262V56.025ZM54 51.25C52.6739 51.25 51.4021 50.7232 50.4645 49.7855C49.5268 48.8479 49 47.5761 49 46.25C49 44.9239 49.5268 43.6521 50.4645 42.7145C51.4021 41.7768 52.6739 41.25 54 41.25C55.3261 41.25 56.5979 41.7768 57.5355 42.7145C58.4732 43.6521 59 44.9239 59 46.25C59 47.5761 58.4732 48.8479 57.5355 49.7855C56.5979 50.7232 55.3261 51.25 54 51.25ZM54 48.75C54.663 48.75 55.2989 48.4866 55.7678 48.0178C56.2366 47.5489 56.5 46.913 56.5 46.25C56.5 45.587 56.2366 44.9511 55.7678 44.4822C55.2989 44.0134 54.663 43.75 54 43.75C53.337 43.75 52.7011 44.0134 52.2322 44.4822C51.7634 44.9511 51.5 45.587 51.5 46.25C51.5 46.913 51.7634 47.5489 52.2322 48.0178C52.7011 48.4866 53.337 48.75 54 48.75Z" fill={(showLiveLocations ? 'white' : '#009241')} />
            </g>
            <defs>
              <filter id="filter0_d_180_16585" x="0" y="0" width="108" height="108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="12.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_180_16585" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_180_16585" result="shape" />
              </filter>
            </defs>
          </svg>
        </button>
        <button onClick={() => (mapType === 'roadmap' ? setMapType('satellite') : setMapType('roadmap'))} className="p-0 btn mb-2">
          <svg width="40" height="40" viewBox="-5 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="bg-white rounded-2">
            <g clipPath="url(#clip0_180_16798)">
              <path fillRule="evenodd" clipRule="evenodd" d="M10.5294 0.799823C10.4725 0.800323 10.4163 0.812979 10.3646 0.836943L5.46656 3.1123L0.56848 0.837103C0.507533 0.808799 0.440437 0.796305 0.373387 0.800776C0.306337 0.805247 0.241494 0.826538 0.184845 0.862684C0.128195 0.898829 0.0815645 0.948664 0.0492583 1.00759C0.016952 1.06651 1.12744e-05 1.13262 0 1.19982L0 12.4462C1.27918e-05 12.5225 0.0218119 12.5971 0.0628297 12.6614C0.103847 12.7256 0.162375 12.7768 0.23152 12.8089L5.29808 15.1625C5.35203 15.1876 5.4109 15.2004 5.4704 15.1998C5.52746 15.1994 5.58376 15.1867 5.63552 15.1627L10.5336 12.8873L15.4317 15.1625C15.4926 15.1908 15.5597 15.2033 15.6267 15.1988C15.6938 15.1943 15.7586 15.173 15.8152 15.1369C15.8718 15.1007 15.9184 15.0509 15.9507 14.992C15.983 14.9331 16 14.867 16 14.7998V3.55342C15.9999 3.48176 15.9805 3.41145 15.9439 3.34984C15.9073 3.28823 15.8549 3.23758 15.792 3.20318C15.6017 3.41494 15.4042 3.62017 15.2 3.81854V14.1729L10.7715 12.1158L10.7491 8.19662C10.5972 8.30732 10.4374 8.40669 10.2709 8.4939L10.2917 12.1173L5.70336 14.2489L5.64432 3.91198L10.2325 1.78062L10.2384 2.8019C10.3998 2.64789 10.5589 2.4915 10.7157 2.33278L10.7122 1.72398L11.1366 1.92126C11.3538 1.74206 11.5549 1.5387 11.7488 1.32334L10.7019 0.837103C10.6479 0.811991 10.589 0.799255 10.5294 0.799823V0.799823ZM0.8 1.8267L5.164 3.8539L5.22336 14.2459L0.8 12.1905V1.8267Z" fill="#009241" />
              <path d="M14.4577 0.139422C14.3684 0.050149 14.2473 0 14.121 0C13.9947 0 13.8736 0.050149 13.7842 0.139422L8.21465 5.70902C8.16825 5.75542 8.13337 5.80918 8.11017 5.86662L7.34937 8.5263C7.31449 8.63622 7.36809 8.6831 7.48665 8.64678L10.1332 7.88966C10.1906 7.86646 10.2444 7.83174 10.2908 7.78518L15.8604 2.21558C15.9497 2.12626 15.9999 2.00511 15.9999 1.87878C15.9999 1.75246 15.9497 1.63131 15.8604 1.54198L14.4577 0.139422ZM13.2455 2.02518L13.9746 2.7543L9.95417 6.7751L9.22489 6.04566L13.2455 2.02518Z" fill="#009241" />
            </g>
            <defs>
              <clipPath id="clip0_180_16798">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>

        </button>
        <button onClick={() => setZoom(zoom + 0.5)} className="p-0 btn mb-2">
          <svg width="40" height="40" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_180_17027)">
              <rect style={{ width: '100%', height: "100%" }} rx="8" fill="white" />
              <path className="svg-paths" d="M62.75 51.25H55.25V58.75H52.75V51.25H45.25V48.75H52.75V41.25H55.25V48.75H62.75V51.25Z" fill="#009241" />
            </g>
            <defs>
              <filter id="filter0_d_180_17027" x="0" y="0" width="108" height="108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="12.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_180_17027" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_180_17027" result="shape" />
              </filter>
            </defs>
          </svg>
        </button>
        <button onClick={() => setZoom(zoom - 0.5)} className="p-0 btn mb-2">
          <svg width="40" height="40" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_180_17034)">
              <rect style={{ width: '100%', height: "100%" }} width="58" height="58" rx="8" fill="white" />
              <path className="svg-paths" d="M62.75 51.25H45.25V48.75H62.75V51.25Z" fill="#009241" />
            </g>
            <defs>
              <filter id="filter0_d_180_17034" x="0" y="0" width="108" height="108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="12.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_180_17034" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_180_17034" result="shape" />
              </filter>
            </defs>
          </svg>
        </button>
        <button onClick={() => setCurrentLocation()} className="p-0 btn">
          <svg width="70" height="70" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_180_16577)">
              <rect x="25" y="21" width="58" height="58" rx="8" fill="white" shapeRendering="crispEdges" />
              <path d="M54 45C51.2375 45 49 47.2375 49 50C49 52.7625 51.2375 55 54 55C56.7625 55 59 52.7625 59 50C59 47.2375 56.7625 45 54 45ZM65.175 48.75C64.6 43.5375 60.4625 39.4 55.25 38.825V36.25H52.75V38.825C47.5375 39.4 43.4 43.5375 42.825 48.75H40.25V51.25H42.825C43.4 56.4625 47.5375 60.6 52.75 61.175V63.75H55.25V61.175C60.4625 60.6 64.6 56.4625 65.175 51.25H67.75V48.75H65.175ZM54 58.75C49.1625 58.75 45.25 54.8375 45.25 50C45.25 45.1625 49.1625 41.25 54 41.25C58.8375 41.25 62.75 45.1625 62.75 50C62.75 54.8375 58.8375 58.75 54 58.75Z" fill="#009241" />
            </g>
            <defs>
              <filter id="filter0_d_180_16577" x="0" y="0" width="108" height="108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="12.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_180_16577" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_180_16577" result="shape" />
              </filter>
            </defs>
          </svg>

        </button>
      </div>
      <div className="top-right">
        {territories.length > 0 && (
          <ManageTerritoryModal fetchTerritories={fetchTerritories} territories={territories} showManageTerritoryModal={showManageTerritoryModal} />
        )}
        <Button onClick={() => { drawingMode === 'polygon' ? setDrawingMode(null) : setDrawingMode('polygon') }} className={`${drawingMode === 'polygon' ? 'btn-success' : 'bg-white text-success'} ms-1`}>
          {drawingMode === 'polygon' ? (
            <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_180_17396)">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.5294 0.799823C10.4725 0.800323 10.4163 0.812979 10.3646 0.836943L5.46656 3.1123L0.56848 0.837103C0.507533 0.808799 0.440437 0.796305 0.373387 0.800776C0.306337 0.805247 0.241494 0.826538 0.184845 0.862684C0.128195 0.898829 0.0815645 0.948664 0.0492583 1.00759C0.016952 1.06651 1.12744e-05 1.13262 0 1.19982L0 12.4462C1.27918e-05 12.5225 0.0218119 12.5971 0.0628297 12.6614C0.103847 12.7256 0.162375 12.7768 0.23152 12.8089L5.29808 15.1625C5.35203 15.1876 5.4109 15.2004 5.4704 15.1998C5.52746 15.1994 5.58376 15.1867 5.63552 15.1627L10.5336 12.8873L15.4317 15.1625C15.4926 15.1908 15.5597 15.2033 15.6267 15.1988C15.6938 15.1943 15.7586 15.173 15.8152 15.1369C15.8718 15.1007 15.9184 15.0509 15.9507 14.992C15.983 14.9331 16 14.867 16 14.7998V3.55342C15.9999 3.48176 15.9805 3.41145 15.9439 3.34984C15.9073 3.28823 15.8549 3.23758 15.792 3.20318C15.6017 3.41494 15.4042 3.62017 15.2 3.81854V14.1729L10.7715 12.1158L10.7491 8.19662C10.5972 8.30732 10.4374 8.40669 10.2709 8.4939L10.2917 12.1173L5.70336 14.2489L5.64432 3.91198L10.2325 1.78062L10.2384 2.8019C10.3998 2.64789 10.5589 2.4915 10.7157 2.33278L10.7122 1.72398L11.1366 1.92126C11.3538 1.74206 11.5549 1.5387 11.7488 1.32334L10.7019 0.837103C10.6479 0.811991 10.589 0.799255 10.5294 0.799823V0.799823ZM0.8 1.8267L5.164 3.8539L5.22336 14.2459L0.8 12.1905V1.8267Z" fill="white" />
                <path d="M14.4577 0.139422C14.3684 0.050149 14.2473 0 14.121 0C13.9947 0 13.8736 0.050149 13.7842 0.139422L8.21465 5.70902C8.16825 5.75542 8.13337 5.80918 8.11017 5.86662L7.34937 8.5263C7.31449 8.63622 7.36809 8.6831 7.48665 8.64678L10.1332 7.88966C10.1906 7.86646 10.2444 7.83174 10.2908 7.78518L15.8604 2.21558C15.9497 2.12626 15.9999 2.00511 15.9999 1.87878C15.9999 1.75246 15.9497 1.63131 15.8604 1.54198L14.4577 0.139422ZM13.2455 2.02518L13.9746 2.7543L9.95417 6.7751L9.22489 6.04566L13.2455 2.02518Z" fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_180_17396">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>

          )
            :
            (
              <svg className="me-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_180_16593)">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.5294 0.799823C10.4725 0.800323 10.4163 0.812979 10.3646 0.836943L5.46656 3.1123L0.56848 0.837103C0.507533 0.808799 0.440437 0.796305 0.373387 0.800776C0.306337 0.805247 0.241494 0.826538 0.184845 0.862684C0.128195 0.898829 0.0815645 0.948664 0.0492583 1.00759C0.016952 1.06651 1.12744e-05 1.13262 0 1.19982L0 12.4462C1.27918e-05 12.5225 0.0218119 12.5971 0.0628297 12.6614C0.103847 12.7256 0.162375 12.7768 0.23152 12.8089L5.29808 15.1625C5.35203 15.1876 5.4109 15.2004 5.4704 15.1998C5.52746 15.1994 5.58376 15.1867 5.63552 15.1627L10.5336 12.8873L15.4317 15.1625C15.4926 15.1908 15.5597 15.2033 15.6267 15.1988C15.6938 15.1943 15.7586 15.173 15.8152 15.1369C15.8718 15.1007 15.9184 15.0509 15.9507 14.992C15.983 14.9331 16 14.867 16 14.7998V3.55342C15.9999 3.48177 15.9805 3.41145 15.9439 3.34984C15.9073 3.28823 15.8549 3.23758 15.792 3.20318C15.6017 3.41494 15.4042 3.62017 15.2 3.81854V14.1729L10.7715 12.1158L10.7491 8.19662C10.5972 8.30732 10.4374 8.40669 10.2709 8.4939L10.2917 12.1173L5.70336 14.2489L5.64432 3.91198L10.2325 1.78062L10.2384 2.8019C10.3998 2.64789 10.5589 2.4915 10.7157 2.33278L10.7122 1.72398L11.1366 1.92126C11.3538 1.74206 11.5549 1.5387 11.7488 1.32334L10.7019 0.837103C10.6479 0.811991 10.589 0.799255 10.5294 0.799823ZM0.8 1.8267L5.164 3.8539L5.22336 14.2459L0.8 12.1905V1.8267Z" fill="#009241" />
                  <path d="M14.4577 0.139422C14.3684 0.050149 14.2473 0 14.121 0C13.9947 0 13.8736 0.050149 13.7842 0.139422L8.21465 5.70902C8.16825 5.75542 8.13337 5.80918 8.11017 5.86662L7.34937 8.5263C7.31449 8.63622 7.36809 8.6831 7.48665 8.64678L10.1332 7.88966C10.1906 7.86646 10.2444 7.83174 10.2908 7.78518L15.8604 2.21558C15.9497 2.12626 15.9999 2.00511 15.9999 1.87878C15.9999 1.75246 15.9497 1.63131 15.8604 1.54198L14.4577 0.139422ZM13.2455 2.02518L13.9746 2.7543L9.95417 6.7751L9.22489 6.04566L13.2455 2.02518Z" fill="#009241" />
                </g>
                <defs>
                  <clipPath id="clip0_180_16593">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )
          }
          Mark Territory
        </Button>
      </div>
      <div className="top-left d-flex flex-row align-items-center">
        <button style={{ borderRadius: 10, backgroundColor: (showLiveLocations ? '#009241' : 'white') }} onClick={() => setShowFilterSection(!showFilterSection)} className="me-5 border-0">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="40" height="40" rx="5" fill={(showLiveLocations ? '#009241' : 'white')} />
            <path d="M12.5 15.125C12.5 14.8266 12.6185 14.5405 12.8295 14.3295C13.0405 14.1185 13.3266 14 13.625 14H26.375C26.6734 14 26.9595 14.1185 27.1705 14.3295C27.3815 14.5405 27.5 14.8266 27.5 15.125C27.5 15.4234 27.3815 15.7095 27.1705 15.9205C26.9595 16.1315 26.6734 16.25 26.375 16.25H13.625C13.3266 16.25 13.0405 16.1315 12.8295 15.9205C12.6185 15.7095 12.5 15.4234 12.5 15.125ZM15.5 19.625C15.5 19.3266 15.6185 19.0405 15.8295 18.8295C16.0405 18.6185 16.3266 18.5 16.625 18.5H23.375C23.6734 18.5 23.9595 18.6185 24.1705 18.8295C24.3815 19.0405 24.5 19.3266 24.5 19.625C24.5 19.9234 24.3815 20.2095 24.1705 20.4205C23.9595 20.6315 23.6734 20.75 23.375 20.75H16.625C16.3266 20.75 16.0405 20.6315 15.8295 20.4205C15.6185 20.2095 15.5 19.9234 15.5 19.625ZM18.875 23C18.5766 23 18.2905 23.1185 18.0795 23.3295C17.8685 23.5405 17.75 23.8266 17.75 24.125C17.75 24.4234 17.8685 24.7095 18.0795 24.9205C18.2905 25.1315 18.5766 25.25 18.875 25.25H21.125C21.4234 25.25 21.7095 25.1315 21.9205 24.9205C22.1315 24.7095 22.25 24.4234 22.25 24.125C22.25 23.8266 22.1315 23.5405 21.9205 23.3295C21.7095 23.1185 21.4234 23 21.125 23H18.875Z" fill={(showLiveLocations ? 'white' : '#009241')} />
          </svg>
        </button>
        <div style={{ padding: "8px 10px" }} className=" bg-white">
          <p className="m-0">Currently Viewing</p>
        </div>
        <div className="dropdown bg-white" style={{ height: 40, padding: 7 }}>
          <select disabled={showLiveLocations} onChange={onSelectedTerritoryChange} style={{ border: 0, outline: 'none' }}>
            <option>Select Territory</option>
            {territories.map((x, key) => {
              return (
                <option key={key} value={JSON.stringify(x)}>{x.territoryname}</option>
              );
            })}
          </select>
        </div>
        {showFilterSection && showLiveLocations && (
          <div style={{ height: 200, width: 200, borderRadius: 10, backgroundColor: 'white', position: 'absolute', top: 65, padding: 15 }}>
            <div className="form-check-territory form-check">
              <label className="form-check-label" htmlFor="all-user-check">
                All Users
              </label>
              <input className="form-check-input-reverse" defaultChecked type="checkbox" onChange={onAllCheckBoxCheck} id="all-user-check" />
            </div>
            <div className="form-check-territory form-check">
              <label className="form-check-label" htmlFor="online-check">
                Online
              </label>
              <input className="form-check-input-reverse" defaultChecked type="checkbox" onChange={onCheckBoxCheck} id="online-check" />
            </div>
            <div className="form-check-territory form-check">
              <label className="form-check-label" htmlFor="offline-check">
                Offline
              </label>
              <input className="form-check-input-reverse" defaultChecked type="checkbox" onChange={onCheckBoxCheck} id="offline-check" />
            </div>
            <div className="form-check-territory form-check">
              <Button className="w-100" variant="success" onClick={setFilters}>Save Filter</Button>
            </div>
          </div>
        )}
      </div>
      {
        showCreateModal && (
          <CreateTerritoryModal onCreateModalClose={onCreateModalClose} setNotification={setNotification} fetchTerritories={fetchTerritories} />
        )
      }
      <ToastContainer className="p-3" position="bottom-end">
        <Toast onClose={() => setToastShow(false)} show={toastShow} delay={3000} autohide className="d-inline-block m-1" style={{ backgroundColor: "#009241" }}>
          <Toast.Header>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24.5741 12.4091H22.9253C22.5667 12.4091 22.2257 12.5813 22.0147 12.8766L16.4882 20.5407L13.9851 17.0673C13.7741 16.7755 13.4366 16.5997 13.0745 16.5997H11.4257C11.1972 16.5997 11.0636 16.8598 11.1972 17.0462L15.5776 23.1212C15.6811 23.2656 15.8175 23.3833 15.9756 23.4645C16.1336 23.5457 16.3087 23.588 16.4864 23.588C16.6641 23.588 16.8392 23.5457 16.9973 23.4645C17.1553 23.3833 17.2917 23.2656 17.3952 23.1212L24.7991 12.8555C24.9362 12.6692 24.8026 12.4091 24.5741 12.4091Z" fill="white" />
              <path d="M18 2.25C9.30234 2.25 2.25 9.30234 2.25 18C2.25 26.6977 9.30234 33.75 18 33.75C26.6977 33.75 33.75 26.6977 33.75 18C33.75 9.30234 26.6977 2.25 18 2.25ZM18 31.0781C10.7789 31.0781 4.92188 25.2211 4.92188 18C4.92188 10.7789 10.7789 4.92188 18 4.92188C25.2211 4.92188 31.0781 10.7789 31.0781 18C31.0781 25.2211 25.2211 31.0781 18 31.0781Z" fill="white" />
            </svg>
            <strong className="me-auto">New Territory created!</strong>
          </Toast.Header>
          <Toast.Body className="text-white">
            The territory {newTerName} has been created successfully
          </Toast.Body>
        </Toast>
      </ToastContainer>

    </Container >
  );
}