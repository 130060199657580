import Router from './routes';
import './App.scss';
import { useEffect } from 'react';
import { query, collection, where, getDocs } from 'firebase/firestore';
import { db } from './firebase';

function App() {

  useEffect(() => {
    const getSuperAdminId = async () => {
      const superAdmin = await getDocs(query(collection(db, "users"), where("isSuperAdmin", "==", true)));
      if (superAdmin.docs) {
        if (superAdmin.docs[0].id) {
          localStorage.setItem('superAdminUid', superAdmin.docs[0].id);
          return superAdmin.docs[0].id
        }
      }
    }
    getSuperAdminId();
  }, []);

  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
