import { Row, Col, Tabs, Tab } from "react-bootstrap";
import PropTypes from 'prop-types';
import Login from '../../sections/auth/login/Login';
import SignUp from "../../sections/auth/signup/SignUp";
import { AuthHead } from "../../sections/auth/AuthHead";

Auth.propTypes = {
    setLoggedIn: PropTypes.func
}

export default function Auth({setLoggedIn}) {
    return (
        <Row className="justify-content-center align-items-center h-100">
            <Col className="pt-4 pb-4" xs={12} sm={6} md={4} style={{ backgroundColor: "white", boxShadow: '0px 10px 54px rgba(0, 0, 0, 0.05)', borderRadius: 10 }}>
                <AuthHead />
                <Tabs className="auth-tabs mb-3 w-100" defaultActiveKey="Login">
                    <Tab eventKey="Login" title="Login">
                        <Login setLoggedIn={setLoggedIn} />
                    </Tab>
                    {/* <Tab eventKey="Signup" title="Signup" >
                        <SignUp />
                    </Tab> */}
                </Tabs>
            </Col>
        </Row>
    );

}