import { Row, Col, Container, Button } from 'react-bootstrap';
import AddTodoSidebar from './AddTodoSideBar';
import { doc, deleteDoc } from "firebase/firestore";
import { db } from '../../firebase';
import PropTypes from 'prop-types';
Header.propTypes = {
  selected: PropTypes.bool,
  fetchTodos: PropTypes.func
};
export default function Header({ selected, fetchTodos }) {

  const deleteMultiple = async () => {
    const cc = document.getElementsByClassName("todo-checkbox");
    const allSelected = [];
    for(var i = 0; i<cc.length; i++) {
      if(cc[i].children[0].children[0].checked) {
         allSelected.push(cc[i].children[0].children[0].value);
      }
    }
    for (const selected of allSelected) {
      await deleteDoc(doc(db, "todo-list", selected))
      .then((res) => {console.log(res)}).catch(() => {
        alert('Something went wrong please try again later');
      });
    }
    window.location.reload();
    
  }

  return (
     <div className='d-flex border-bottom'>
       <Col>
          <Container>
            <Row>
              <Col className='pt-4 pb-3' sm={6}>
                <Button onClick={deleteMultiple} variant="success" disabled={!selected} className='me-2'>Delete</Button>
              </Col>
              <Col className='pt-4 pb-3 text-end' sm={6}>
                {/* <Button variant="light" className='me-2'>This Week</Button> */}
                <AddTodoSidebar fetchTodos={fetchTodos} />
              </Col>
            </Row>
          </Container>
       </Col>
     </div>
  )
}
