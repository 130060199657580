import PropTypes from 'prop-types';
IconHeading.propTypes = {
  icon: PropTypes.node,
  heading: PropTypes.string,
  subHeading: PropTypes.string
};

function IconHeading({icon, heading, subHeading}) {
  return (
    <>
      <h1 className='icon-heading text-start'><span>{icon}</span> {heading}</h1>
      <p className='icon-sub-heading text-start'>{subHeading}</p>
    </>
  );
}

export default IconHeading;