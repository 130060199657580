
import { useState, useEffect } from 'react';
import { Button, Offcanvas, Form, InputGroup, FormControl } from 'react-bootstrap';
import { collection, addDoc, getDocs, where, query } from "firebase/firestore";
import { db, auth } from '../../firebase';
import { notifySuperAdmin, notifyUserById } from '../../utils/notifications';
function OffCanvasComponent({ fetchTodos, ...props }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [assignees, setAssignees] = useState([]);
  // const [selectedAssignees, setSelectedAssignees] = useState({});

  async function fetchUsers() {
    const querySnapshot = await getDocs(query(collection(db, "users"), where('approvedByAdmin', '==', true), where('role', '==', 'User')));
    let allUsers = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      allUsers.push({
        id: doc.id,
        fullName: doc.data().fullName,
      });
      // console.log(doc.id, " => ", doc.data());
    });
    setAssignees(allUsers);
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const addTodoItem = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, "todo-list"), {
      todo_description: e.target.description.value,
      todo_title: e.target.title.value,
      status: 0,
      due_date: e.target.dueDate.value,
      user: JSON.parse(e.target.assignee.value),
      createdBy: auth.currentUser.uid
    }).then(async (res) => {
      handleClose();
      await notifySuperAdmin('TODO Task Created', 'Admin Created TODO Task');
      await notifyUserById(JSON.parse(e.target.assignee.value).id, 'TODO Task Created', 'You Have Been Assigned To A TODO Task')
      fetchTodos();
    }).catch((err) => alert(err.message));
  }

  const dataPicker = (e) => {
   e.target.showPicker();
  }
  return (
    <>
      <Button variant="success" onClick={handleShow} className='me-2'>+ Add Todo</Button>
      <Offcanvas className="rounded" show={show} onHide={handleClose} backdrop={false} {...props}>
        <Offcanvas.Header closeButton className='border-bottom' style={{ flexDirection: 'column' }}>
          <Offcanvas.Title>Add New Todo</Offcanvas.Title>
          <p className='text-black-50 m-0'>Enter fields here to create new todo</p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={addTodoItem}>
            <Form.Label htmlFor='email' as='h6'>Todo</Form.Label>
            <InputGroup className='mb-3'>
              <FormControl
                id='todo'
                placeholder='Enter Todo'
                aria-label='todo'
                aria-describedby='Todo'
                name="title"
                type="text"
              />
            </InputGroup>
            <Form.Label htmlFor='t-description' as='h6'>Todo Description</Form.Label>
            <InputGroup className='mb-3'>
              <FormControl
                id='t-description'
                type='textarea'
                name="description"
                as="textarea"
                placeholder='Enter Todo'
                aria-label='todo-Description'
                aria-describedby='todo-Description' />
            </InputGroup>
            <Form.Label htmlFor='assignee' as='h6'>Assign Todo</Form.Label>
            <InputGroup className='mb-3'>
              <Form.Select
                id='assignee'
                name="assignee"
                placeholder='Enter Todo'
                aria-label='todo-Description'
                aria-describedby='todo-Description'
              >
                <option>Select Assignee</option>
                {assignees.map((x, key) => (
                  <option key={key} value={JSON.stringify(x)}>{x.fullName}</option>  
                ))}
              </Form.Select>
            </InputGroup>
            <Form.Label htmlFor='due-date' as='h6'>Due Date</Form.Label>
            <InputGroup className='mb-3'>
              <InputGroup.Text className='bg-transparent' id='due-date-label'><i className='text-success bi bi-calendar2'></i></InputGroup.Text>
              <FormControl
                id='due-date'
                name="dueDate"
                type='date'
                onClick={dataPicker}
                placeholder='Select Date'
                aria-label='select-date'
                aria-describedby='select-date'
                
              />
            </InputGroup>
            <Button variant="success" type="submit" onClick={handleShow} className='me-2 w-100'>Create Todo</Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function AddTodoSidebar({fetchTodos}) {
  return (
    <OffCanvasComponent placement="end" fetchTodos={fetchTodos} />
  );
}
