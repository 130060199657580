const navConfig = [
  {
    path: 'overview',
    label: 'Overview'
  },
  {
    path: 'campaigns',
    label: 'Campaigns'
  },
  {
    path: 'territory',
    label: 'Territory'
  },
  {
    path: 'todo',
    label: 'Todo'
  },
  {
    path: 'reports',
    label: 'Reports'
  },
  {
    path: 'users',
    label: 'Users'
  },
  {
    path: 'configurations',
    label: 'Configurations'
  }
];


export default navConfig;