import { useEffect, useState } from 'react';
import Header from "../../sections/reports/Header";
import ReportsTabs from "../../sections/reports/Tabs";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from '../../firebase';

const Reports = ({ }) => {
  const [users, setUsers] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [territories, setTerritories] = useState([]);

  const fetchUsers = async () => {
    const querySnapshot = await getDocs(query(collection(db, "users"), where('approvedByAdmin', '==', true), where('role', '==', 'User')));
    let allUsers = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      allUsers.push({
        id: doc.id,
        fullName: doc.data().fullName,
        avatar: doc.data().avatar !== undefined ? doc.data().avatar : '',
        email: doc.data().email,
        designation: doc.data().designation,
        phone: doc.data().phone,
        role: doc.data().role
      });
      // console.log(doc.id, " => ", doc.data());
    });
    setUsers(allUsers);
  }

  const fetchTerritories = async () => {
    const q = await getDocs(collection(db, 'territories'));
    let ter = [];
    for (const qDocs of q.docs) {
      ter.push({
        assignees: qDocs.data().assignees,
        campaigns: qDocs.data().campaigns,
        created: qDocs.data().created,
        territoryname: qDocs.data().territoryname,
        id: qDocs.id
      });
    }
    setTerritories(ter);
  }

  const fetchCampaigns = async () => {
    let querySnapshot = function(){}
    if(localStorage.getItem('superAdminUid') === localStorage.getItem('currentUserId')) {
      querySnapshot = await getDocs(collection(db, "campaigns"));
    } else {
      querySnapshot = await getDocs(query(collection(db, "campaigns"), where('createdBy', '==', localStorage.getItem('currentUserId'))));
    }
    
    let allCampaigns = [];
    for (const document of querySnapshot.docs) {
      allCampaigns.push({
        id: document.id,
        name: document.data().name,
        dateRange: document.data().date_range,
        status: document.data().status,
        details: document.data().details,
      });
    }
    setCampaigns(allCampaigns);
  }

  useEffect(() => {
    fetchUsers();
    fetchCampaigns();
    fetchTerritories();
  }, [])

  return (
    <>
      <Header />
      {users.length > 0 &&
        <ReportsTabs users={users} territories={territories} campaigns={campaigns} />
      }
    </>
  )
}

export default Reports;