import React, { useEffect, useState } from 'react';
import { Col, Row, Container, Card } from 'react-bootstrap';
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from '../../firebase';

const Overview = ({ }) => {

  const [createdTer, setCreatedTer] = useState(0);
  const [ter, setTer] = useState([]);
  const [createdCam, setCreatedCam] = useState(0);
  const [cam, setCam] = useState([]);
  const [assignedTasks, setAssignedTasks] = useState(0);
  const [users, setUsers] = useState([]);

  const fetchCampaigns = async () => {
    const querySnapshot = await getDocs(collection(db, "campaigns"));
    setCreatedCam(querySnapshot.docs.length);
    const fetchCam = [];
    for (const cam of querySnapshot.docs) {
      fetchCam.push(
        {
          id: cam.id,
          name: cam.data().name,
          leads: 0
        }
      );
    }
    for (const cam of fetchCam) {
      const querySnapshotLeads = await getDocs(query(collection(db, "leads"), where('campaignId', '==', cam.id)));
      const index = fetchCam.findIndex(x => x.id === cam.id);
      fetchCam[index].leads = querySnapshotLeads.docs.length;
    }
    setCam(fetchCam);
  }

  const fetchTeritorries = async () => {
    const querySnapshot = await getDocs(collection(db, "territories"));
    setCreatedTer(querySnapshot.docs.length);
    const fetchTer = [];
    for (const ter of querySnapshot.docs) {
      fetchTer.push(
        {
          id: ter.id,
          name: ter.data().territoryname,
          leads: 0
        }
      );
    }
    for (const ter of fetchTer) {
      const querySnapshotLeads = await getDocs(query(collection(db, "leads"), where('territoryId', '==', ter.id)));
      const index = fetchTer.findIndex(x => x.id === ter.id);
      fetchTer[index].leads = querySnapshotLeads.docs.length;
    }
    setTer(fetchTer);
  }

  const fetchTasks = async () => {
    const querySnapshot = await getDocs(query(collection(db, "todo-list"), where('status', '==', 0)));
    setAssignedTasks(querySnapshot.docs.length);
  }

  const fetchLeads = async () => {
    const querySnapshot = await getDocs(query(collection(db, "users"), where('role', '==', 'User')));
    const fetchUsers = [];
    for (const user of querySnapshot.docs) {
      fetchUsers.push(
        {
          fullName: user.data().fullName,
          id: user.id,
          interested: 0,
          notHome: 0,
          comeBack: 0,
          notInterested: 0,
          emptyProperty: 0,
          noOfCamp: 0
        }
      );
    }
    for (const user of fetchUsers) {
      const querySnapshotLeads = await getDocs(query(collection(db, "leads"), where('userId', '==', user.id)));
      const index = fetchUsers.findIndex(x => x.id === user.id);
      for (const document of querySnapshotLeads.docs) {
        if (document.data().status === 1) {
          fetchUsers[index].interested += 1
        } else if (document.data().status === 2) {
          fetchUsers[index].notHome += 1
        } else if (document.data().status === 3) {
          fetchUsers[index].comeBack += 1
        } else if (document.data().status === 4) {
          fetchUsers[index].notInterested += 1
        } else if (document.data().status === 5) {
          fetchUsers[index].emptyProperty += 1
        }
      }
      const campaigns = await getDocs(query(collection(db, "campaigns"), where('assignees', 'array-contains', user.id)));
      fetchUsers[index].noOfCamp = campaigns.docs.length
    }
    setUsers(fetchUsers);
    document.getElementById("agent-overview-card").setAttribute("style", "height: " + (window.innerHeight - document.getElementById("agent-overview-card").getBoundingClientRect().top - 70) + "px; overflow-y: scroll; overflow-x: hidden")
    document.getElementById("campaign-overview-card").setAttribute("style", "height: " + ((parseInt(document.getElementById("agent-overview-card").style.height, 10) / 2) - 5) + "px; overflow-y: scroll; overflow-x: hidden");
    document.getElementById("territory-overview-card").setAttribute("style", "height: " + ((parseInt(document.getElementById("agent-overview-card").style.height, 10) / 2) - 5) + "px; overflow-y: scroll; overflow-x: hidden");
  }

  useEffect(() => {
    fetchCampaigns();
    fetchTeritorries();
    fetchTasks();
    fetchLeads();
  }, []);

  return (
    <React.Fragment>
      <Container>
        <Row className='mt-4 mb-4'>
          <Col className='ms-2 me-2 p-3' style={{ borderRadius: 10, backgroundColor: '#009241', color: 'white', height: 160 }}>
            <p className='mb-5'>Territories created</p>
            <h1>{createdTer}</h1>
          </Col>
          <Col className='ms-2 me-2 p-3' style={{ borderRadius: 10, backgroundColor: '#7C9EB2', color: 'white', height: 160 }}>
            <p className='mb-5'>Campaigns created</p>
            <h1>{createdCam}</h1>
          </Col>
          <Col className='ms-2 me-2 p-3' style={{ borderRadius: 10, backgroundColor: '#BFBFBF', color: 'white', height: 160 }}>
            <p className='mb-5'>Task Assigned</p>
            <h1>{assignedTasks}</h1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col sm={7}>
            <Card>
              <Card.Body id="agent-overview-card">
                <Card.Title>Agent Overview</Card.Title>
                <Row className='text-center'>
                  <Col sm={4}></Col>
                  <Col sm={1}>I</Col>
                  <Col sm={1}>NH</Col>
                  <Col sm={1}>CB</Col>
                  <Col sm={1}>NI</Col>
                  <Col sm={1}>EP</Col>
                  <Col sm={3}>Campaigns</Col>
                </Row>
                {users.map((x, key) => {
                  return (
                    <Row className='border p-3 rounded mb-2 mt-2' key={key.toString()}>
                      <Col sm={4}>{x.fullName}</Col>
                      <Col className='text-center' sm={1}>{x.interested}</Col>
                      <Col className='text-center' sm={1}>{x.notHome}</Col>
                      <Col className='text-center' sm={1}>{x.comeBack}</Col>
                      <Col className='text-center' sm={1}>{x.notInterested}</Col>
                      <Col className='text-center' sm={1}>{x.emptyProperty}</Col>
                      <Col className='text-center' sm={3}>{x.noOfCamp}</Col>
                    </Row>
                  )
                })}
              </Card.Body>
            </Card>
          </Col>
          <Col sm={5}>
            <Row className='mb-2'>
              <Col sm={12}>
                <Card>
                  <Card.Body id="campaign-overview-card">
                    <Card.Title>Campaign Overview</Card.Title>
                    {cam.map((x, key) => {
                      return (
                        <Row key={key.toString()}>
                          <Col sm={7}>{x.name}</Col>
                          <Col className='text-center' sm={5}>{x.leads} Leads</Col>
                        </Row>
                      )
                    })}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col sm={12}>
                <Card>
                  <Card.Body id="territory-overview-card">
                    <Card.Title>Territory Overview</Card.Title>
                    {ter.map((x, key) => {
                      return (
                        <Row key={key.toString()}>
                          <Col sm={7}>{x.name}</Col>
                          <Col className='text-center' sm={5}>{x.leads} Leads</Col>
                        </Row>
                      )
                    })}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Overview;