import { Container, Row, Col, Nav } from "react-bootstrap";

export default function TabsHeaders() {
  return (
    <div className='d-flex border-bottom'>
      <Container>
        <Row>
          <Col>
            <Nav variant="tabs" className="app-tabs flex-row">
              <Nav.Item>
                <Nav.Link eventKey="all-members">All Members</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="approved">Approved</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Pending">pending</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="role-management">Role Management</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="track-users">Track Users</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
}