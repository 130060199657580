import { useEffect, useState, useMemo } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { collection, getDocs, query} from "firebase/firestore";
import moment from 'moment';
import TrackLocation from './TrackLocationModal';
import { db } from '../../firebase';
import Pagination from '../../components/pagination/pagination';

export default function TrackMembers({ isLoaded }) {
  const [users, setUsers] = useState([]);
  let PageSize = 10;

  const [currentPage, setCurrentPage] = useState(0);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return users.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, users]);

  async function fetchUsers() {
    const querySnapshot = await getDocs(query(collection(db, "users")));
    let allUsers = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      if(doc.data().lastLogin && doc.data().lastLocation) {
        allUsers.push({
          id: doc.id,
          fullName: doc.data().fullName,
          email: doc.data().email,
          avatar: doc.data().avatar !== undefined ? doc.data().avatar : '',
          role: doc.data().role || 'User',
          designation: doc.data().designation || '',
          lastLogin: doc.data().lastLogin,
          lastLocation: doc.data().lastLocation
        });
      }
      // console.log(doc.id, " => ", doc.data());
    });
    setCurrentPage(1);
    setUsers(allUsers);
    if(allUsers.length > 0) {
      document.getElementById("user-tabs-tabpane-track-users").setAttribute("style", "height: "+ (window.innerHeight - document.getElementById("user-tabs-tabpane-all-members").getBoundingClientRect().top - 70)+"px; overflow-y: scroll; overflow-x: hidden")
    }
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const colors = ['#009241', '#7B61FF', '#FCDE00'];
  return (
    <>
      {currentTableData.map((x, key) => {
        return (
          <Row key={key} className='border-bottom'>
            <Col>
              <Container>
                <Row className='pt-4 pb-4'>
                  <Col sm={1} className='align-self-center'>
                    {x.avatar !== '' && (
                      <img style={{
                        height: 50,
                        width: 50,
                        borderRadius: 100,
                      }} src={x.avatar} alt="avatar" />
                    )}
                    {x.avatar === '' && (
                      <div className='border'
                        style={{
                          height: 43,
                          width: 43,
                          borderRadius: 100,
                          padding: 10,
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          backgroundColor: colors[Math.floor(Math.random(0) * colors.length)]
                        }}>
                        {`${x.fullName.includes(' ') ? x.fullName.split(' ')[0].substring(0, 1) + x.fullName.split(' ')[1].substring(0, 1) : x.fullName.substring(0, 1) }`}
                      </div>
                    )}
                  </Col>
                  <Col sm={3} className='align-self-center'>
                    <p className='m-0'>{`${x.fullName.includes(' ') ? x.fullName.split(' ')[0] + ' ' + x.fullName.split(' ')[1] : x.fullName }`}</p>
                    <p className='m-0 text-black-50'>Last Online {moment(new Date(x.lastLogin)).fromNow()}</p>
                  </Col>
                  <Col sm={5} className='align-self-center'>
                    {x.email}
                  </Col>
                  <Col sm={3} className='align-self-center text-end'>
                    <TrackLocation isLoaded={isLoaded} name={x.fullName} location={x.lastLocation} id={x.id} />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        );
      }
      )}
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={users.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      />
      {currentTableData.length === 0 &&
        (
          <p className='text-center'>No Approved users Found</p>  
        )
      }
    </>
  );
}