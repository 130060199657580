import { Row, Col, Container, Form, Button } from 'react-bootstrap';
import { updateDoc, doc } from "firebase/firestore";
import { db } from '../../firebase';
// import { todos } from '../../assets/mocks';
import PropTypes from 'prop-types';
import EditCampaignModal from './EditCampaignModal';
import ViewQuestionaireModal from './ViewQuestionaireModal';
import ViewLeadsModal from './ViewLeadsModal';
CampaignListings.propTypes = {
  setSelected: PropTypes.func,
  allCampaigns: PropTypes.array.isRequired,
  setAllCampaigns: PropTypes.func,
  fetchCampaigns: PropTypes.func
};

export default function CampaignListings({ setSelected, allCampaigns, setAllCampaigns, fetchCampaigns }) {

  const onselectAllCheckbox = (event) => {
    const cc = document.getElementsByClassName("campaigns-checkbox");
    for (var i = 0; i < cc.length; i++) {
      cc[i].children[0].children[0].checked = event.target.checked
    }
    setSelected(event.target.checked);
  }

  const onSingleCHeckboxSelected = () => {
    const cc = document.getElementsByClassName("campaigns-checkbox");
    for (var i = 0; i < cc.length; i++) {
      if (cc[i].children[0].children[0].checked) {
        setSelected(true);
        break;
      } else {
        setSelected(false);
      }
    }
  }

  const setActiveOrInActive = async (id, value, key) => {
    await updateDoc(doc(db, "campaigns", id), {
      status: value
    })
      .then((res) => {
        let camp = allCampaigns;
        camp[key].status = value;
        setAllCampaigns(JSON.parse(JSON.stringify(camp)));
      }).catch(() => {
        alert('Something went wrong please try again later');
      });
  }

  return (
    <Container fluid>
      <Row className='border-bottom'>
        <Col sm={1} className='border-end pt-3 pb-3 text-end'>
          <Form.Group controlId="check-all-todos">
            <Form.Check onChange={onselectAllCheckbox} type="checkbox" />
          </Form.Group>
        </Col>
        <Col style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Campaigns</Col>
        <Col style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Questionaire</Col>
        <Col style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Leads</Col>
        <Col style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Campaign Deadline</Col>
        <Col style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Campaign Status</Col>
      </Row>
      <Row id="campaign-listings-table-body">
        <Col>
          {allCampaigns.map((x, key) => {
            return (
              <Row key={key} className='pt-4 pb-4'>
                <Col sm={1} className='text-end align-self-end'>
                  <Form.Group className="mb-3 campaigns-checkbox" controlId={`checkbox-${key}`}>
                    <Form.Check disabled={!x.status} value={x.id} onChange={onSingleCHeckboxSelected} type="checkbox" />
                  </Form.Group>
                </Col>
                <Col className='align-self-center'>
                  <p className={x.status ? 'text-black' : 'text-black-50'}>{x.name}</p>
                </Col>
                <Col className='d-flex justify-content-between align-self-center'>
                  <ViewQuestionaireModal campaignId={x.id} />
                </Col>
                <Col className='d-flex justify-content-between align-self-center'>
                  <ViewLeadsModal campaignId={x.id} />
                </Col>
                <Col className='d-flex justify-content-between align-self-center'>
                  <p className={x.status ? 'text-black' : 'text-black-50'}>{x.dateRange.from} - {x.dateRange.to}</p>
                </Col>
                <Col className='d-flex justify-content-around align-self-center'>
                  <Form.Check
                    type="switch"
                    id={`custom-switch-${x.id}`}
                    label=""
                    defaultChecked={x.status}
                    onChange={(e) => setActiveOrInActive(x.id, e.target.checked, key)}
                  />
                  <EditCampaignModal campaign={x} fetchCampaigns={fetchCampaigns} />
                </Col>
              </Row>
            );
          }
          )}
        </Col>
      </Row>
    </Container>
  );
}