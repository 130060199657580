import { useState } from 'react';
import Header from "../../sections/users/Header";
import UserTabs from "../../sections/users/Tabs";
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase';

const Users = ({ isLoaded }) => {
  const [designations, setDesignations] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  async function fetchDesignations() {
    const querySnapshot = await getDocs(collection(db, "company-roles"));
    let allDesignations = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      allDesignations.push({
        id: doc.id,
        designation: doc.data().designation,
      });
      // console.log(doc.id, " => ", doc.data());
    });
    setCurrentPage(1);
    setDesignations(allDesignations);
  }

  return (
    <>
      <Header fetchDesignations={fetchDesignations} />
      <UserTabs isLoaded={isLoaded} fetchDesignations={fetchDesignations} designations={designations} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </>  
  )
}

export default Users;