import { Row, Col, Container, Button, InputGroup, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import AddCampaignModal from './AddCampaignModal';
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import { db } from '../../firebase';

Header.propTypes = {
  selected: PropTypes.bool,
  searchOnChange: PropTypes.func,
  fetchCampaigns: PropTypes.func
};

export default function Header({ selected, searchOnChange, fetchCampaigns }){

  const deleteMultiple = async () => {
    const cc = document.getElementsByClassName("campaigns-checkbox");
    const allSelected = [];
    for(var i = 0; i<cc.length; i++) {
      if(cc[i].children[0].children[0].checked) {
         allSelected.push(cc[i].children[0].children[0].value);
      }
    }
    for (const selected of allSelected) {
      await deleteDoc(doc(db, "campaigns", selected))
      .then((res) => {fetchCampaigns()}).catch(() => {
        alert('Something went wrong please try again later');
      });
    }    
  }

  const setInActive = async () => {
    const cc = document.getElementsByClassName("campaigns-checkbox");
    const allSelected = [];
    for(var i = 0; i<cc.length; i++) {
      if(cc[i].children[0].children[0].checked) {
         allSelected.push(cc[i].children[0].children[0].value);
      }
    }
    for (const selected of allSelected) {
      await updateDoc(doc(db, "campaigns", selected), {
        status: false
      })
      .then((res) => {
        for(var i = 0; i<cc.length; i++) {
          if(cc[i].children[0].children[0].checked) {
            cc[i].children[0].children[0].checked = false
            document.getElementById(`custom-switch-${cc[i].children[0].children[0].value}`).checked = false;
          }
        }
        fetchCampaigns()
        
      }).catch((e) => {
        console.log(e)
        alert('Something went wrong please try again later');
      });
    }
  }

  return (
    <div className='d-flex border-bottom'>
      <Col>
        <Container>
          <Row>
            <Col className='pt-4 pb-3' sm={6}>
              <Button onClick={deleteMultiple} variant="success" disabled={!selected} className='me-2'>Delete</Button>
              <Button onClick={setInActive} variant="success" disabled={!selected} className='me-2'>Inactive</Button>
            </Col>
            <Col className='pt-4 pb-3 text-end' sm={6}>
              <Row>
                <Col sm={7}>
                  <InputGroup className="mb-3 border rounded">
                    <FormControl
                      placeholder="Search campaign"
                      aria-label="Search campaign"
                      className="border-0"
                      onChange={(e) => {searchOnChange(e)}}
                    />
                    <Button variant="outline-secondary" className="border-0">
                      <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2694 9.88563L12.9462 12.5619L12.0619 13.4462L9.38563 10.7694C8.38984 11.5676 7.15125 12.0018 5.875 12C2.77 12 0.25 9.48 0.25 6.375C0.25 3.27 2.77 0.75 5.875 0.75C8.98 0.75 11.5 3.27 11.5 6.375C11.5018 7.65125 11.0676 8.88984 10.2694 9.88563ZM9.01562 9.42188C9.80882 8.60618 10.2518 7.51277 10.25 6.375C10.25 3.9575 8.29187 2 5.875 2C3.4575 2 1.5 3.9575 1.5 6.375C1.5 8.79187 3.4575 10.75 5.875 10.75C7.01277 10.7518 8.10618 10.3088 8.92188 9.51562L9.01562 9.42188Z" fill="#7C9EB2" />
                      </svg>
                    </Button>
                  </InputGroup>
                </Col>
                <Col>
                  <AddCampaignModal fetchCampaigns={fetchCampaigns} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Col>
    </div>
  )
}