// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, updatePassword} from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBswQPjoJwH_C3JSNe3zYP9IQ7aTkDkOnE",
  authDomain: "d2d-new-fc399.firebaseapp.com",
  projectId: "d2d-new-fc399",
  storageBucket: "d2d-new-fc399.appspot.com",
  messagingSenderId: "1028982807047",
  appId: "1:1028982807047:web:168b72b3a2bf20cacf28a2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app)
const uP = updatePassword;
const storage = getStorage(app);

export {auth, db, uP, storage}