import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Download } from 'react-bootstrap-icons';
import Select from "react-select";
import PropTypes from 'prop-types';
import { useEffect, useState } from "react";
import { collection, getDocs, where, query, doc, getDoc } from "firebase/firestore";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { db } from '../../firebase';
import moment from "moment";


const ExportToExcel = ({ apiUserData, campaign, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (fileName, apiUserData, campaign) => {
    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_aoa(ws, [['Campaign Name', campaign.name],['Start Date', campaign.dateRange.from],['End Date', campaign.dateRange.to], ['No Of Responses', campaign.noOfResponses]], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(ws, [['Summary of Campaign'], ['Interested', campaign.interested], ['Not Home', campaign.notHome], ['Come Back', campaign.comeBack], ['Not Interested', campaign.notInterested], ['Empty Property', campaign.emptyProperty]], { origin: "A7" });
    // XLSX.utils.sheet_add_aoa(ws, [['Responses'], ['Customer Name', 'Postal Code', 'Address', 'Email Address', 'Phone Number', 'User Agent', ]], { origin: "A14" });
    XLSX.utils.sheet_add_aoa(ws, [['Responses']], { origin: "A14" });
    XLSX.utils.sheet_add_json(ws, apiUserData, {origin: "A15"});
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button onClick={(e) => exportToCSV(fileName, apiUserData, campaign)} variant="transparent" style={{color:"#009241"}}><Download className="me-1 text-success" /> Download Report</Button>
  );
};

CampaignsReportTable.propTypes = {
  territories: PropTypes.array,
  campaigns: PropTypes.array
}
export default function CampaignsReportTable({ territories, campaigns }) {
  const [selectedCampaigns, setSelectedCampaigns] = useState({});
  const [options, setOptions] = useState([]);
  const [excUserData, setExcUserData] = useState([]);
  useEffect(() => {
    let op = [];
    if (campaigns.length > 0) {
      campaigns.forEach((x) => {
        op.push({
          value: JSON.stringify({ 'id': x.id, 'name': x.name, 'dateRange': x.dateRange, 'details': x.details, 'status': x.status }),
          label: `${x.name} (${x.dateRange.from} - ${x.dateRange.to})`
        });
      });
      setOptions(op)
    }

  }, [])

  const convertLeadIntToString = (status) => {
    if(status === 1) {
      return 'interested';
    } else if (status === 2) {
      return 'Not Home'
    } else if (status === 3) {
      return 'Come Back'
    } else if (status === 4) {
      return 'Not Interested'
    } else if (status === 5) {
      return 'Empty Property'
    }
    return '-';
  }

  const onCampaignSelect = async (val) => {
    let campaign = {
      name: JSON.parse(val.value).name,
      id: JSON.parse(val.value).id,
      dateRange: JSON.parse(val.value).dateRange,
      status: JSON.parse(val.value).status,
      details: JSON.parse(val.value).details,
      noOfResponses: 0,
      interested: 0,
      notHome: 0,
      comeBack: 0,
      notInterested: 0,
      emptyProperty: 0
    };

    let workingTeriitory = [];
    let workingTerIndex = [];
    territories.forEach((x, key) => {
      x.campaigns.forEach((cam) => {
        if(cam.id === campaign.id) {
          workingTerIndex.push(key);
        }
      });
    });

    workingTerIndex = workingTerIndex.filter(function(item, pos, self) {
      return self.indexOf(item) == pos;
    })
    workingTerIndex.forEach((index) => {
      workingTeriitory.push(territories[index]);
    });
    let repData = [];
    for (const wt of workingTeriitory) {
      const querySnapshot = await getDocs(query(collection(db, "leads"), where("territoryId", "==", wt.id)));
      campaign.noOfResponses = (campaign.noOfResponses + querySnapshot.docs.length);
      for (const document of querySnapshot.docs) {
        if(document.data().status === 1) {
          campaign.interested += 1
        } else if (document.data().status === 2) {
          campaign.notHome += 1
        } else if (document.data().status === 3) {
          campaign.comeBack += 1
        } else if (document.data().status === 4) {
          campaign.notInterested += 1
        } else if (document.data().status === 5) {
          campaign.emptyProperty += 1
        }
        const userQuerySnapShot = await getDoc(doc(db, 'users', document.data().userId));
        
        if(userQuerySnapShot.data()) {
          repData.push({
            'Customer Name': document.data().customer_name ? document.data().customer_name : '-',
            'Postal Code': document.data().postalCode ? document.data().postalCode : '-',
            'Address': document.data().customer_address ? document.data().customer_address : '-',
            'Email Address': document.data().customer_email ? document.data().customer_email : '-',
            'Phone Number': document.data().customer_phone ? document.data().customer_phone : '-',
            'User Agent': `${userQuerySnapShot.data().fullName}/${userQuerySnapShot.data().email}`,
            'Territory Name': wt.territoryname,
            'Lead Status': convertLeadIntToString(document.data().status),
            'Pin Date': moment.unix(document.data().pin_date.seconds).format('DD/MM/yy')
          });
          const answerQuerySnapShot = await getDocs(query(collection(db, "answers"), where("leadId", "==", document.id)));
          for (const ans of answerQuerySnapShot.docs) {
            for (const [key, value] of Object.entries(ans.data().data)) {
              if(key !== 'leadId') {
                if(Array.isArray(value)){
                  repData[repData.length-1][key] = '';
                  for (const val of value) {
                    repData[repData.length-1][key] += val+', ';
                  }
                  repData[repData.length-1][key] = repData[repData.length-1][key].substr(0, repData[repData.length-1][key].length - 2);
                  
                } else {
                  repData[repData.length-1][key] = value;
                }
                
              }
            }
          }
        }
        
      }
    }
    setExcUserData(repData);
    setSelectedCampaigns(campaign);
  }
  return (
    <div>
      <Container fluid className="p-0">
        <Row className="m-4 ms-0">
          <Col sm={6} className="p-0">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Campaign</Form.Label>
              <Select
                // value={selectedUser}
                onChange={onCampaignSelect}
                closeMenuOnSelect={true}
                options={options}
                hideSelectedOptions={true}
                isMulti={false}
                isSearchable
              />
            </Form.Group>
          </Col>
        </Row>
      </Container>
      <Row className='border rounded'>
        <Col sm={4} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Full Name</Col>
        <Col sm={1} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Interested</Col>
        <Col sm={1} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Not Home</Col>
        <Col sm={1} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Come back</Col>
        <Col sm={2} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Not Interested</Col>
        <Col sm={2} style={{ fontWeight: 'bolder' }} className='pt-3 pb-3'>Empty Property</Col>
      </Row>
      {selectedCampaigns.name !== undefined &&
        <Row className='pt-4 pb-4 border rounded'>
          <Col sm={4} className='align-self-center'>
            <p className='m-0'>{`${selectedCampaigns.name} (${selectedCampaigns.dateRange.from} - ${selectedCampaigns.dateRange.to})`}</p>
            <p className='m-0 text-black-50'>Status: {selectedCampaigns.status ? 'Active' : 'In Active'}</p>
          </Col>
          <Col sm={1} className='d-flex justify-content-between align-self-center'>
            {selectedCampaigns.interested ? selectedCampaigns.interested : 0}
          </Col>
          <Col sm={1} className='d-flex justify-content-between align-self-center'>
            {selectedCampaigns.notHome ? selectedCampaigns.notHome : 0}
          </Col>
          <Col sm={1} className='d-flex justify-content-between align-self-center'>
            {selectedCampaigns.comeBack ? selectedCampaigns.comeBack : 0}
          </Col>
          <Col sm={2} className='d-flex justify-content-between align-self-center'>
            {selectedCampaigns.notInterested ? selectedCampaigns.notInterested : 0}
          </Col>
          <Col sm={1} className='d-flex justify-content-between align-self-center'>
            {selectedCampaigns.emptyProperty ? selectedCampaigns.emptyProperty : 0}
          </Col>
          <Col sm={2} className='d-flex justify-content-end align-self-center'>
            <ExportToExcel apiUserData={excUserData} campaign={selectedCampaigns} fileName={`${selectedCampaigns.name}-report`} />
          </Col>
        </Row>
      }
    </div>
  );

}