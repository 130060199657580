import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import './AuthModule.scss';
function AuthLayout() {
  return (
    <Container fluid style={{ backgroundColor: "black", height: "100vh", backgroundImage: `url(${require('../../assets/png/auth-bg.png')})`, backgroundSize: "100%", backgroundRepeat: "no-repeat" }}>
      <Outlet />
    </Container>
  );
}

export default AuthLayout;