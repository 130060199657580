import { Row, Col, Container, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import IconHeading from '../../components/icon-heading/IconHeading';
import { People } from 'react-bootstrap-icons';
import InviteUserModal from './InviteUserModal';
import AddNewRole from './AddNewRole';

Header.propTypes = {
  fetchDesignations: PropTypes.func
}

export default function Header({ fetchDesignations }){
  return (
     <div className='d-flex border-bottom'>
       <Col>
          <Container>
            <Row>
              <Col className='pt-4 pb-3' sm={6}>
                <IconHeading icon={<People className="me-1"/>} heading='Team Members' subHeading='Edit and add new team members here' />
              </Col>
              <Col className='pt-4 pb-3 text-end' sm={6}>
                <AddNewRole fetchDesignations={fetchDesignations} />
                <InviteUserModal />
              </Col>
            </Row>
          </Container>
       </Col>
     </div>
  )
}
