import { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from '../../../firebase';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';

Login.propTypes = {
  setLoggedIn: PropTypes.func
}

export default function Login({ setLoggedIn }) {
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const history = useNavigate();
  const logIn = (e) => {
    e.preventDefault()
    const email = e.target.email.value;
    const password = e.target.password.value;
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        localStorage.setItem("token", JSON.stringify(userCredential.user));
        const currentUser = await getDoc(doc(db, "users", auth.currentUser.uid));
        localStorage.setItem('role', currentUser.data().role);
        localStorage.setItem('currentUserId', auth.currentUser.uid);
        if(currentUser.data().isSuperAdmin) {
          localStorage.setItem('isSuperAdmin', true);
        }
        setLoggedIn(true)
        history('/dashboard/overview');
      })
      .catch(() => {
        // alert(error.message);
        setErrorMsg('The credentials you have entered are incorrect. Please try again.');
      });
  }

  return (
    <Container className='text-start fluid'>
      <Row>
        <Col>
          <p>Enter the details below to sign in to your account</p>
        </Col>
      </Row>
      <Row>
        <Form onSubmit={logIn}>
          {/* email bar */}
          <Form.Label htmlFor='email' as='h6'>Email</Form.Label>
          <InputGroup className='mb-3'>
            <InputGroup.Text className='bg-transparent' id='email'><i className='text-success bi bi-envelope'></i></InputGroup.Text>
            <FormControl
              id='email'
              name='email'
              type='email'
              className='border-start-0'
              placeholder='Enter login email'
              aria-label='email'
              aria-describedby='email' />
          </InputGroup>
          {/* password bar */}
          <Form.Label htmlFor='password' as='h6'>Password</Form.Label>
          <InputGroup className='mb-2'>
            <InputGroup.Text className='bg-transparent' id='password'><i className='text-success bi bi-lock'></i></InputGroup.Text>
            <FormControl
              id='password'
              className='border-start-0'
              name='password'
              type='password'
              placeholder='Enter log in password'
              aria-label='password'
              aria-describedby='password'
              onChange={(e) => {
                if (e.target.value.length > 6) {
                  setAllowSubmit(true);
                } else {
                  setAllowSubmit(false)
                }
              }}
            />
          </InputGroup>
          <p className='text-danger'>{errorMsg}</p>
          {/* button */}
          <Button className='w-100' variant='success' disabled={!allowSubmit} type='submit'>Sign in</Button>
          <Row className='justify-content-between mt-3'>
            <Col>
              <Form.Group className='mb-2' controlId='rem-checkbox'>
                <Form.Check type='checkbox' label='Remember me' />
              </Form.Group>
            </Col>
            <Col className='text-end'>
              <a href='#' className='text-success text-decoration-none'>Forgot Password?</a>
            </Col>
          </Row>
        </Form>
      </Row>
    </Container >
  )
}

// export default Login;