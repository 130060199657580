import { Row, Col, Container, Form } from 'react-bootstrap';
import moment from 'moment';
// import { todos } from '../../assets/mocks';
import EditTodoSidebar from './EditTodoSideBar';
import PropTypes from 'prop-types';
import '../../pages/todo/Todo.page.scss'
TodoListings.propTypes = {
  setSelected: PropTypes.func,
  fetchTodos: PropTypes.func,
  allTodos: PropTypes.array
};

export default function TodoListings({ setSelected, allTodos, fetchTodos }) {

  const onselectAllCheckbox = (event) => {
    const cc = document.getElementsByClassName("todo-checkbox");
    for (var i = 0; i < cc.length; i++) {
      cc[i].children[0].children[0].checked = event.target.checked
    }
    setSelected(event.target.checked);
  }

  const onSingleCHeckboxSelected = () => {
    const cc = document.getElementsByClassName("todo-checkbox");
    for (var i = 0; i < cc.length; i++) {
      if (cc[i].children[0].children[0].checked) {
        setSelected(true);
        break;
      } else {
        setSelected(false);
      }
    }
  }

  return (
    <Container fluid>
      <Row className='border-bottom'>
        <Col sm={1} className='border-end pt-3 pb-3 text-end'>
          <Form.Group controlId="check-all-todos">
            <Form.Check onChange={onselectAllCheckbox} type="checkbox" />
          </Form.Group>
        </Col>
        <Col sm={4} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>To Do’s</Col>
        <Col sm={2} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Assignee</Col>
        <Col sm={2} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Status</Col>
        <Col sm={3} style={{ fontWeight: 'bolder' }} className='border-end pt-3 pb-3'>Due Date</Col>
      </Row>
      <Row id="todo-listings-table-body">
        <Col>
          {allTodos.map((x, key) => {
            return (
              <Row key={key} className='pt-4 pb-4'>
                <Col sm={1} className='text-end align-self-end'>
                  <Form.Group className="mb-3 todo-checkbox" controlId={`checkbox-${key}`}>
                    <Form.Check value={x.id} onChange={onSingleCHeckboxSelected} type="checkbox" />
                  </Form.Group>
                </Col>
                <Col sm={4} className='align-self-center'>
                  <p className='m-0' style={{ fontWeight: 'bolder' }}>{x.title}</p>
                  <p className='m-0 text-black-50 to-do-description'>{x.description}</p>
                </Col>
                <Col sm={2} className='align-self-center'>{x.assignee.fullName}</Col>
                {console.log(x.status === 0)}
                <Col sm={2} className='align-self-center'>{x.status === 0 ? ((x.status === 0 && moment(x.dueDate, 'YYYY-MM-D').isBefore(new Date())) ? 'Over Due' : 'Assigned' ) : x.status === 1 && 'Complete' }</Col>
                <Col sm={3} className='d-flex justify-content-between align-self-center'>
                  <p>{x.dueDate}</p>
                  <EditTodoSidebar todo={x} fetchTodos={fetchTodos} />
                </Col>
              </Row>
            );
          }
          )}
        </Col>
      </Row>
    </Container>
  );
}