import { useState, useEffect, useMemo } from "react";
import debounce from 'lodash.debounce';
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from '../../firebase';
import CampaignListings from "../../sections/campaigns/CampaignsListings";
import Header from "../../sections/campaigns/Header";
import Pagination from '../../components/pagination/pagination';

import './Campaigns.page.scss';

const Campaigns = () => {
  const [selected, setSelected] = useState(false);
  const [allCampaigns, setAllCampaigns] = useState([]);
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return allCampaigns.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, allCampaigns]);

  async function fetchCampaigns() {
    let querySnapshot = function(){}
    if(localStorage.getItem('superAdminUid') === localStorage.getItem('currentUserId')) {
      querySnapshot = await getDocs(collection(db, "campaigns"));
    } else {
      querySnapshot = await getDocs(query(collection(db, "campaigns"), where('createdBy', '==', localStorage.getItem('currentUserId'))));
    }
    let campaigns = [];
    querySnapshot.forEach((doc) => {
      campaigns.push({
        id: doc.id,
        name: doc.data().name,
        dateRange: doc.data().date_range,
        details: doc.data().details,
        status: doc.data().status,
        assignees: doc.data().assignees ? doc.data().assignees : []
      });
    });
    setAllCampaigns(campaigns);
    setCurrentPage(1);
    document.getElementById("campaign-listings-table-body").setAttribute("style", "height: "+ (window.innerHeight - document.getElementById("campaign-listings-table-body").getBoundingClientRect().top - 70)+"px; overflow-y: scroll; overflow-x: hidden")
  }

  useEffect(() => {
    fetchCampaigns();
  }, []);

  const searchOnChange = (e) => {

    const debouncedFilter = debounce(async () => {
      const querySnapshot = await getDocs(query(collection(db, "campaigns"), where('name', '>=', e.target.value.toUpperCase()), where('name', '<=', e.target.value.toLowerCase() + '~')));
      let campaigns = [];
      querySnapshot.forEach((doc) => {
        campaigns.push({
          id: doc.id,
          name: doc.data().name,
          dateRange: doc.data().date_range,
          assignee: doc.data().assignees,
          status: doc.data().status
        });
      });
      setAllCampaigns(campaigns);
    }, 500)
    debouncedFilter()
  }

  return (
    <>
      <Header searchOnChange={searchOnChange} selected={selected} fetchCampaigns={fetchCampaigns} />
      <CampaignListings fetchCampaigns={fetchCampaigns} setSelected={setSelected} allCampaigns={currentTableData} setAllCampaigns={setAllCampaigns} />
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={allCampaigns.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      />
      {currentTableData.length === 0 &&
        (
          <p className='text-center'>No campaign Found</p>  
        )
      }
    </>
  )
}

export default Campaigns;