import { useState, useEffect, useMemo } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { auth, db } from '../../firebase';
import Header from "../../sections/todo/Header";
import TodoListings from '../../sections/todo/TodoListings';
import Pagination from '../../components/pagination/pagination';
import './Todo.page.scss';
const Todo = () => {
  const [selected, setSelected] = useState(false);
  const [allTodos, setAllTodos] = useState([]);
  let PageSize = 10;
  const [currentPage, setCurrentPage] = useState(0);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return allTodos.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, allTodos]);

  async function fetchTodos() {
    let querySnapshot = function(){}
    if(localStorage.getItem('superAdminUid') === localStorage.getItem('currentUserId')) {
      querySnapshot = await getDocs(collection(db, "todo-list"));
    } else {
      querySnapshot = await getDocs(query(collection(db, "todo-list"), where('createdBy', '==', localStorage.getItem('currentUserId'))));
    }
    let todos = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      todos.push({
        id: doc.id,
        description: doc.data().todo_description,
        title: doc.data().todo_title,
        dueDate: doc.data().due_date.toString(),
        assignee: doc.data().user,
        status: doc.data().status
      });
      // console.log(doc.id, " => ", doc.data());
    });
    setAllTodos(todos);
    setCurrentPage(1);
    if(todos.length > 0) {
      document.getElementById("todo-listings-table-body").setAttribute("style", "height: "+ (window.innerHeight - document.getElementById("todo-listings-table-body").getBoundingClientRect().top - 70)+"px; overflow-y: scroll; overflow-x: hidden")
    } else {
      document.getElementById("todo-listings-table-body").setAttribute("style", "height: 0px; overflow-y: scroll; overflow-x: hidden")
    }
  }

  useEffect(() => {
    fetchTodos();
  }, []);

  
  return (
    <>
      <Header selected={selected} fetchTodos={fetchTodos} />
      <TodoListings setSelected={setSelected} allTodos={currentTableData} fetchTodos={fetchTodos} />
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={allTodos.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      />
      {currentTableData.length === 0 &&
        (
          <p className='text-center'>No Todos Found</p>  
        )
      }
    </>  
  )
}

export default Todo;