import { useEffect, useState } from "react";
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import { db } from '../../firebase';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import { doc, updateDoc, getDocs, collection, where, query } from "firebase/firestore";
import PropTypes from 'prop-types';
import 'react-multiple-select-dropdown-lite/dist/index.css';

EditCampaignModal.propTypes = {
  campaign: PropTypes.object,
  fetchCampaigns: PropTypes.func
}

export default function EditCampaignModal({ campaign, fetchCampaigns }) {
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [assignees, setAssignees] = useState(campaign.assignees ? campaign.assignees : []);
  const handleClose = () => { setShow(false); };
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(query(collection(db, "users"), where('approvedByAdmin', '==', true), where('role', '==', 'User')));
      let allUsers = [];
      querySnapshot.forEach((doc) => {
        allUsers.push({
          value: doc.id,
          label: doc.data().fullName,
        });
      });
      setUsers(allUsers);
    }
    fetchUsers();
  }, []);

  const onAssigneeSelect = e => {
    var arr = [];
    e.forEach((x) => {
      arr.push(x.value);
    })
    // arr.push(JSON.parse(e));
    setAssignees(arr);
  }

  const editCampaign = async e => {
    e.preventDefault()
    const assigned = assignees;
    // Create a new user with email and password using firebase
    await updateDoc(doc(db, "campaigns", campaign.id), {
      date_range: { from: e.target.startDate.value, to: e.target.endDate.value },
      assignees: assigned
    }).then((res) => {
      fetchCampaigns();
      handleClose();
    }).catch((err) => alert(err.message));

  }

  const dataPicker = (e) => {
    e.target.showPicker();
  }

  return (
    <>
      <Button onClick={handleShow} variant="transparent" className='me-2'>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.75568 15.8906C3.80479 15.8906 3.85389 15.8857 3.903 15.8783L8.03291 15.154C8.08202 15.1442 8.12867 15.1221 8.16304 15.0853L18.5713 4.677C18.5941 4.65428 18.6121 4.6273 18.6244 4.5976C18.6368 4.5679 18.6431 4.53606 18.6431 4.5039C18.6431 4.47174 18.6368 4.4399 18.6244 4.4102C18.6121 4.38049 18.5941 4.35351 18.5713 4.3308L14.4905 0.247536C14.4438 0.200884 14.3825 0.176331 14.3162 0.176331C14.2499 0.176331 14.1885 0.200884 14.1418 0.247536L3.73358 10.6558C3.69675 10.6926 3.67465 10.7368 3.66483 10.7859L2.9405 14.9158C2.91661 15.0474 2.92515 15.1828 2.96536 15.3102C3.00558 15.4377 3.07627 15.5535 3.1713 15.6475C3.33336 15.8047 3.53715 15.8906 3.75568 15.8906ZM5.41059 11.6085L14.3162 2.70535L16.1159 4.50513L7.21037 13.4083L5.02755 13.7937L5.41059 11.6085ZM19.0354 17.9531H0.963937C0.529339 17.9531 0.178223 18.3042 0.178223 18.7388V19.6228C0.178223 19.7308 0.266616 19.8192 0.374651 19.8192H19.6247C19.7327 19.8192 19.8211 19.7308 19.8211 19.6228V18.7388C19.8211 18.3042 19.47 17.9531 19.0354 17.9531Z" fill="#009241" />
        </svg>
      </Button>
      <Modal centered className="edit-territories-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0 flex-column">
          <Modal.Title className="w-100">Edit Your Campaign</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={editCampaign}>
            <Form.Label htmlFor='start-date' as='h6'>Start Date</Form.Label>
            <InputGroup className='mb-3'>
              <InputGroup.Text className='bg-transparent' id='due-date-label'><i className='text-success bi bi-calendar2'></i></InputGroup.Text>
              <FormControl
                id='start-date'
                name="startDate"
                type='date'
                onClick={dataPicker}
                placeholder='Select Date'
                aria-label='select-date'
                aria-describedby='select-date'
                defaultValue={campaign.dateRange.from}
              />
            </InputGroup>
            <Form.Label htmlFor='end-date' as='h6'>End Date</Form.Label>
            <InputGroup className='mb-3'>
              <InputGroup.Text className='bg-transparent' id='due-date-label'><i className='text-success bi bi-calendar2'></i></InputGroup.Text>
              <FormControl
                id='end-date'
                name="endDate"
                type='date'
                onClick={dataPicker}
                placeholder='Select Date'
                aria-label='select-date'
                aria-describedby='select-date'
                defaultValue={campaign.dateRange.to}
              />
            </InputGroup>
            <Form.Label htmlFor='assignee' as='h6'>Assign Campaigns To</Form.Label>
            <InputGroup className='mb-3'>
              <MultiSelect
                onChange={onAssigneeSelect}
                options={users}
                defaultValue={assignees}
                jsonValue
                style={{
                  width: "100%"
                }}
              />
            </InputGroup>
            <InputGroup className="mb-2 mt-3 d-flex justify-content-end">
              <Button className="rounded" variant='success' type="submit">Update</Button>
            </InputGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
