import { useEffect, useState } from "react";
import { Button, Modal, Row, Col } from 'react-bootstrap';
import { db } from '../../firebase';
import { getDocs, query, collection, where } from "firebase/firestore";
import PropTypes from 'prop-types';
import 'react-multiple-select-dropdown-lite/dist/index.css';

ViewQuestionaireModal.propTypes = {
  campaignId: PropTypes.string
}

export default function ViewQuestionaireModal({ campaignId }) {
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); };
  const handleShow = () => setShow(true);
  const [questions, setQuestions] = useState([]);

  const fetchQuestionaire = async () => {
    const querySnapshotTer = await getDocs(query(collection(db, "campaign-questionnaire"), where("campaignId", "==", campaignId)));
    if(querySnapshotTer.docs.length > 0) {
      console.log(querySnapshotTer.docs[0].data())
      setQuestions(querySnapshotTer.docs[0].data().questions);
    }
  }

  useEffect(() => {
    fetchQuestionaire();
  }, []);

  return (
    <>
      <Button onClick={handleShow} variant="success" className='me-2 rounded'>
        View Questionaire
      </Button>
      <Modal centered size="lg" className="edit-territories-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0 flex-column">
          <Modal.Title className="w-100">View Questionaire</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {questions.map((x, key) => {
            return (
              <Row key={key}>
                <Col sm="12">
                  <h5>Q{key+1}) {x.value}</h5>
                </Col>
                {x.answer.type === "Text box" && (
                  <Col sm="12">
                    <p>Type: {x.answer.type}</p>
                  </Col>  
                )}
                {x.answer.type === 'Text Area' && (
                  <Col sm="12">
                    <p>Type: {x.answer.type}</p>
                  </Col>  
                )}
                {x.answer.type === 'Dropdowns' && (
                  <Col sm="12">
                    <Row>
                      <Col sm="12">
                        <p>Type: {x.answer.type}</p>
                      </Col>
                      <Col sm="12">
                      <p>Options: {x.answer.options.map((option, opk) => (<span key={opk}> Option {opk+1}: {option}{opk+1 !== x.answer.options.length ? ', ' : '' }</span>))}</p>
                      </Col>
                    </Row>
                  </Col>  
                )}
                {x.answer.type === 'Checkboxes' && (
                  <Col sm="12">
                    <Row>
                      <Col sm="12">
                        <p>Type: {x.answer.type}</p>
                      </Col>
                      <Col sm="12">
                        <p>Options: {x.answer.options.map((option, opk) => (<span key={opk}> Option {opk+1}: {option.value.getData}{opk+1 !== x.answer.options.length ? ', ' : '' }</span>))}</p>
                      </Col>
                    </Row>
                  </Col>  
                )}
                {x.answer.type === 'Radio buttons' && (
                  <Col sm="12">
                    <Row>
                      <Col sm="12">
                        <p>Type: {x.answer.type}</p>
                      </Col>
                      <Col sm="12">
                        <p>Options: {x.answer.options.map((option, opk) => (<span key={opk}> Option {opk+1}: {option.value}{opk+1 !== x.answer.options.length ? ', ' : '' }</span>))}</p>
                      </Col>
                    </Row>
                  </Col>  
                )}
              </Row>  
            )
          })}
        </Modal.Body>
      </Modal>
    </>
  );
}
