
import React from 'react';
import { Outlet } from "react-router";
import PropTypes from 'prop-types';
import Header from './Header';
import './DashboardModule.scss';

DashboardLayout.propTypes = {
  logout: PropTypes.func
}

function DashboardLayout({logout}) {
  return (
    <React.Fragment>
      <Header logout={logout} />
      <Outlet />
    </React.Fragment>
  )
}

export default DashboardLayout;