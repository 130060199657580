import { useEffect, useState } from "react";
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import { auth, db } from '../../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, getDocs, collection } from "firebase/firestore";
import { notifySuperAdmin } from "../../utils/notifications";

export default function InviteUserModal() {
  const [show, setShow] = useState(false);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [designations, setDesignations] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchDesignations = async () => {
    const querySnapshot = await getDocs(collection(db, "company-roles"));
    let allDesignations = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      allDesignations.push({
        id: doc.id,
        designation: doc.data().designation,
      });
      // console.log(doc.id, " => ", doc.data());
    });
    setDesignations(allDesignations);
  }

  useEffect(() => {
    fetchDesignations();
  }, []);

  const register = e => {
    e.preventDefault()
    const email = e.target.email.value;
    const password = e.target.password.value;
    const name = e.target.fullName.value;
    const role = e.target.role.value;
    const designation = e.target.designation.value;
    // Create a new user with email and password using firebase
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (res) => {
        localStorage.setItem("token", JSON.stringify(res.user));
        await setDoc(doc(db, "users", res.user.uid), {
          fullName: name,
          email: email,
          approvedByAdmin: true,
          role: role,
          designation: designation
        });
        if(e.target.role.value === "Admin") {
          await notifySuperAdmin('New Admin Inivted', 'New Admin Has Been Invited To The System');
        } else if (e.target.role.value === "User") {
          await notifySuperAdmin('New User Invited', 'New User Has Been Invited To The System');
        }
        window.location.reload();
        // sendEmailVerification(auth.currentUser)
        // .then(() => {
        //   history('/verify-email')
        // }).catch((err) => alert(err.message))
      }).catch((err) => alert(err.message))
  }

  return (
    <>
      <Button variant="warning" onClick={handleShow}>Invite new user</Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="border-0 flex-column">
          <Modal.Title className="w-100">Add New User</Modal.Title>
          <p className="text-black-50 w-100">Enter the correct fields to invite a new user</p>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={register}>
            <Form.Label htmlFor="fullName" as="h6">Full name </Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Text className="bg-transparent" id="fullNameIcon"><i className="text-success bi bi-person"></i></InputGroup.Text>
              <FormControl
                id="fullName"
                name="fullName"
                type="text"
                className="border-start-0"
                placeholder="Enter your fullname"
                aria-label="Fullname"
                aria-describedby="fullname"
                required
              />
            </InputGroup>
            <Form.Label htmlFor='role' as='h6'>Assign Role</Form.Label>
            <InputGroup className='mb-3'>
              <Form.Select
                id='role'
                name="role"
                placeholder='Select role'
                aria-label='role'
                aria-describedby='role'
                required
              >
                <option value="">Select role</option>
                <option value="Admin">Admin</option>
                <option value="User">User</option>
              </Form.Select>
            </InputGroup>
            <Form.Label htmlFor='designation' as='h6'>Assign Designation</Form.Label>
            <InputGroup className='mb-3'>
              <Form.Select
                id='designation'
                name="designation"
                placeholder='Select Designation'
                aria-label='designation'
                aria-describedby='designation'
                required

              >
                <option value="">Select designation</option>
                {designations.map((x, key) => {
                  return (
                    <option key={key} value={x.designation}>{x.designation}</option>
                  )
                })
                }
              </Form.Select>
            </InputGroup>
            <Form.Label htmlFor='email' as='h6'>Email</Form.Label>
            <InputGroup className='mb-2'>
              <InputGroup.Text className='bg-transparent' id='email'><i className='text-success bi bi-envelope'></i></InputGroup.Text>
              <FormControl
                id="email"
                name="email"
                type="email"
                className="border-start-0"
                placeholder="Enter email"
                aria-label="email"
                aria-describedby="email"
                required
              />
            </InputGroup>
            <Form.Label htmlFor="Choosepassword" as="h6">Login Password</Form.Label>
            <InputGroup className="mb-2">
              <InputGroup.Text className="bg-transparent" id="choosepassword"><i className="text-success bi bi-lock"></i></InputGroup.Text>
              <FormControl
                id="choosepassword"
                name="password"
                type="password"
                className="border-start-0"
                placeholder="Enter login password"
                aria-label="Fullname"
                aria-describedby="choosepassword"
                required
                onChange={(e) => {
                  if (e.target.value.length > 6) {
                    setAllowSubmit(true);
                  } else {
                    setAllowSubmit(false)
                  }
                }}
              />
            </InputGroup>
            <InputGroup className="mb-2 mt-3 justify-content-between">
              <Button style={{ width: '45%' }} className="rounded" onClick={handleClose} variant="secondary" type="reset">Cancel</Button>
              <Button style={{ width: '45%' }} className="rounded" variant='success' type="submit" disabled={!allowSubmit}>Send Invite</Button>
            </InputGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
