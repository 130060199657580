import { useState } from "react";
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { Button, Modal } from 'react-bootstrap';
import { db } from '../../firebase';

TrackLocation.propTypes = {
  name: PropTypes.string,
  location: PropTypes.object,
  id: PropTypes.string
}

export default function TrackLocation({ name, location, id, isLoaded }) {
  const [show, setShow] = useState(false);
  const [zoom, setZoom] = useState(20);
  const [center, setCenter] = useState(location);
  const [currentLocation, setCurrentLocation] = useState(location);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    unsubscribe();
  };

  const unsubscribe = onSnapshot(query(collection(db, "users"), where(id, "==", id)), (snapshot) => {
   
  });

  const containerStyle = () => {
    var header = document.getElementById("app-header");
    let containerHeight = 0;
    containerHeight = (window.innerHeight - header.offsetHeight) - 2;
    let containerWidth = 100;
    const options = {
      height: `${containerHeight - 100}px`,
      width: `${containerWidth}%`
    }
    return options;
  }

  const setCurrentLocationBtn = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCenter(pos);
          setZoom(20);
        },
        error => alert(error)
      );
    }
  }

  return (
    <>
      <Button onClick={handleShow} className='bi-geo-alt btn-success'> &nbsp; Track User Location</Button>
      <Modal centered show={show} onHide={handleClose} className="edgy-modal" size="lg">
        <Modal.Header closeButton className="border-0 flex-column">
          <Modal.Title className="w-100">{name}</Modal.Title>
          <p className="text-black-50 w-100">You can check user live location here</p>
        </Modal.Header>
        <Modal.Body>
          {isLoaded && (
            <GoogleMap
              id="user-tracker-map"
              mapTypeId="roadmap"
              mapContainerStyle={containerStyle()}
              center={center}
              zoom={zoom}
              options={{
                disableDefaultUI: true,
              }}
            >
              <Marker
                onLoad={() => { }}
                icon='/trackingmarker.png'
                position={{ lat: currentLocation.lat, lng: currentLocation.lng }}
              />
            </GoogleMap>
          )}
          <div className="bottom-right d-flex flex-column">
            <button onClick={() => setCurrentLocationBtn()} className="p-0 btn">
              <svg width="70" height="70" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_180_16577)">
                  <rect x="25" y="21" width="58" height="58" rx="8" fill="white" shapeRendering="crispEdges" />
                  <path d="M54 45C51.2375 45 49 47.2375 49 50C49 52.7625 51.2375 55 54 55C56.7625 55 59 52.7625 59 50C59 47.2375 56.7625 45 54 45ZM65.175 48.75C64.6 43.5375 60.4625 39.4 55.25 38.825V36.25H52.75V38.825C47.5375 39.4 43.4 43.5375 42.825 48.75H40.25V51.25H42.825C43.4 56.4625 47.5375 60.6 52.75 61.175V63.75H55.25V61.175C60.4625 60.6 64.6 56.4625 65.175 51.25H67.75V48.75H65.175ZM54 58.75C49.1625 58.75 45.25 54.8375 45.25 50C45.25 45.1625 49.1625 41.25 54 41.25C58.8375 41.25 62.75 45.1625 62.75 50C62.75 54.8375 58.8375 58.75 54 58.75Z" fill="#009241" />
                </g>
                <defs>
                  <filter id="filter0_d_180_16577" x="0" y="0" width="108" height="108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="12.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_180_16577" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_180_16577" result="shape" />
                  </filter>
                </defs>
              </svg>

            </button>
          </div>
          <div style={{top: 40}} className="top-right d-flex flex-column">
            <button onClick={() => setZoom(zoom + 0.5)} className="p-0 btn mb-2">
              <svg width="40" height="40" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_180_17027)">
                  <rect style={{ width: '100%', height: "100%" }} rx="8" fill="white" />
                  <path className="svg-paths" d="M62.75 51.25H55.25V58.75H52.75V51.25H45.25V48.75H52.75V41.25H55.25V48.75H62.75V51.25Z" fill="#009241" />
                </g>
                <defs>
                  <filter id="filter0_d_180_17027" x="0" y="0" width="108" height="108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="12.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_180_17027" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_180_17027" result="shape" />
                  </filter>
                </defs>
              </svg>
            </button>
            <button onClick={() => setZoom(zoom - 0.5)} className="p-0 btn mb-2">
              <svg width="40" height="40" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_180_17034)">
                  <rect style={{ width: '100%', height: "100%" }} width="58" height="58" rx="8" fill="white" />
                  <path className="svg-paths" d="M62.75 51.25H45.25V48.75H62.75V51.25Z" fill="#009241" />
                </g>
                <defs>
                  <filter id="filter0_d_180_17034" x="0" y="0" width="108" height="108" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="12.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_180_17034" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_180_17034" result="shape" />
                  </filter>
                </defs>
              </svg>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
