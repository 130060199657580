import { useState } from "react";
import { Button, Modal, Form, InputGroup, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { db } from '../../firebase';
import { addDoc, collection } from "firebase/firestore";

AddNewDesignation.propTypes = {
  fetchDesignations: PropTypes.func
}

export default function AddNewDesignation({ fetchDesignations }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const addNewDesignation = async e => {
    e.preventDefault()
    const name = e.target.role.value;
    const docRef = await addDoc(collection(db, "company-roles"), {
      designation: name
    }).then((res) => {
      fetchDesignations();
      setShow(false);
    }).catch((err) => alert(err.message));
  }

  return (
    <>
      <Button variant="success" onClick={handleShow} className='me-2'>+ Add New Role</Button>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0 flex-column">
          <Modal.Title className="w-100">Add New Role</Modal.Title>
          <p className="text-black-50 w-100">Enter the fields to add a new role</p>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={addNewDesignation}>
            <Form.Label htmlFor="role" as="h6">Enter role name </Form.Label>
            <InputGroup className="mb-2">
              <FormControl
                id="role"
                name="role"
                type="text"
                placeholder="Enter role name"
                aria-label="role"
                aria-describedby="role"
                required
              />
            </InputGroup>
            <InputGroup className="mb-2 mt-3 justify-content-between">
              <Button style={{ width: '45%' }} className="rounded" onClick={handleClose} variant="secondary" type="reset">Cancel</Button>
              <Button style={{ width: '45%' }} className="rounded" variant='success' type="submit">Create Role</Button>
            </InputGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
