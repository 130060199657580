import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import navConfig from './navConfig';

function NavBar() {
  return (
    <Navbar className='pe-5 px-5 pb-0' collapseOnSelect expand="lg">
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="me-auto w-100 d-flex justify-content-between">
        {navConfig.map((navItem, key) => (
          <Nav.Link as={RouterLink} className='text-center w-100' key={key} to={navItem.path}>{navItem.label}</Nav.Link>
        ))}
      </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
