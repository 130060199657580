import React, { useEffect, useState } from "react";
import { Button, Modal, Form, InputGroup } from 'react-bootstrap';
import { db } from '../../firebase';
import MultiSelect from 'react-multiple-select-dropdown-lite';
import { doc, updateDoc, getDocs, collection } from "firebase/firestore";
import PropTypes from 'prop-types';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import { notifyCampaignUsers, notifySuperAdmin } from "../../utils/notifications";

EditTerritoryModal.propTypes = {
  territory: PropTypes.object,
  marker: PropTypes.bool,
  onClose: PropTypes.func,
  fetchTerritories: PropTypes.func,
  name: PropTypes.string
}

export default function EditTerritoryModal({ territory, marker, onClose, name, fetchTerritories }) {
  const [show, setShow] = useState((true && marker));
  const [allowSubmit] = useState(true);
  // const [users, setUsers] = useState([]);
  // const [userUpdate, setUserUpdate] = useState(false);
  const [campaginUpdate, setCampaginUpdate] = useState(false);
  const [assignees, setAssignees] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const handleClose = () => { setShow(false); onClose(name); };
  useEffect(() => {
    // const fetchUsers = async () => {
    //   const querySnapshot = await getDocs(collection(db, "users"));
    //   let allUsers = [];
    //   querySnapshot.forEach((doc) => {
    //     // doc.data() is never undefined for query doc snapshots
    //     const data = doc.data();
    //     data.id = doc.id;
    //     allUsers.push({
    //       value: JSON.stringify(data),
    //       label: doc.data().fullName,
    //     });
    //   });
    //   setAssignees(setDefaultAssignees(territory, allUsers));

    //   setUsers(allUsers);
    // }

    const fetchCampaigns = async () => {
      const querySnapshot = await getDocs(collection(db, "campaigns"));
      let allCampaigns = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const data = doc.data();
        data.id = doc.id;
        allCampaigns.push({
          value: JSON.stringify(data),
          label: doc.data().name,
        });
      });
      setCampaigns(setDefaultCampaigns(territory, allCampaigns));
      setAllCampaigns(allCampaigns)

    }

    // fetchUsers();
    fetchCampaigns();
  }, []);


  const editTerritory = async e => {
    e.preventDefault()
    const assigned = [];
    // if (userUpdate) {
    //   assignees.forEach((x) => {
    //     assigned.push(JSON.parse(x));
    //   });
    // } else {
    //   assignees.forEach((x) => {
    //     assigned.push(JSON.parse(x.value));
    //   });
    // }
    const selectedCampaign = [];
    if (campaginUpdate) {
      campaigns.forEach((x) => {
        selectedCampaign.push(JSON.parse(x));
      });
    } else {
      campaigns.forEach((x) => {
        selectedCampaign.push(JSON.parse(x.value));
      });
    }
    
    // Create a new user with email and password using firebase
    await updateDoc(doc(db, "territories", territory.id), {
      // assignees: assigned,
      campaigns: selectedCampaign
    }).then((res) => {
      setShow(false);
      if(selectedCampaign.length > 0) {
        for (const camp of selectedCampaign) {
          notifyCampaignUsers(camp, 'Assigned To Campaign', `You Are Assigned To ${camp.name}`);
          notifySuperAdmin('Users Assigned To Campaign', `Admin Assigned Users To ${camp.name}`);
        }
      }
      fetchTerritories();
    }).catch((err) => alert(err.message));
  }

  // const onAssigneeSelect = e => {
  //   var arr = [];
  //   e.forEach((x) => {
  //     arr.push(x.value);
  //   })
  //   // arr.push(JSON.parse(e));
  //   setAssignees(arr);
  //   setUserUpdate(true);
  // }

  const onCampaignSelect = e => {
    var arr = [];
    e.forEach((x) => {
      arr.push(x.value);
    })
    // arr.push(JSON.parse(e));
    setCampaigns(arr);
    setCampaginUpdate(true);
  }

  // const setDefaultAssignees = (territory, users) => {
  //   const selected = [];
  //   territory.assignees.forEach((ele) => {
  //     selected.push(users[users.findIndex(assignee => JSON.parse(assignee.value).id === (ele.id ? ele.id : ele.uid))])
  //   });
  //   return selected;
  // }

  const setDefaultCampaigns = (territory, campaigns) => {
    const selected = [];
    territory.campaigns.forEach((ele) => {
      selected.push(campaigns[campaigns.findIndex(campaign => JSON.parse(campaign.value).id === (ele.id ? ele.id : ele.uid))])
    });
    return selected;
  }

  return (
    <>
      <Button onClick={() => setShow(true)} variant="transparent" className='me-2'>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.75568 15.8906C3.80479 15.8906 3.85389 15.8857 3.903 15.8783L8.03291 15.154C8.08202 15.1442 8.12867 15.1221 8.16304 15.0853L18.5713 4.677C18.5941 4.65428 18.6121 4.6273 18.6244 4.5976C18.6368 4.5679 18.6431 4.53606 18.6431 4.5039C18.6431 4.47174 18.6368 4.4399 18.6244 4.4102C18.6121 4.38049 18.5941 4.35351 18.5713 4.3308L14.4905 0.247536C14.4438 0.200884 14.3825 0.176331 14.3162 0.176331C14.2499 0.176331 14.1885 0.200884 14.1418 0.247536L3.73358 10.6558C3.69675 10.6926 3.67465 10.7368 3.66483 10.7859L2.9405 14.9158C2.91661 15.0474 2.92515 15.1828 2.96536 15.3102C3.00558 15.4377 3.07627 15.5535 3.1713 15.6475C3.33336 15.8047 3.53715 15.8906 3.75568 15.8906ZM5.41059 11.6085L14.3162 2.70535L16.1159 4.50513L7.21037 13.4083L5.02755 13.7937L5.41059 11.6085ZM19.0354 17.9531H0.963937C0.529339 17.9531 0.178223 18.3042 0.178223 18.7388V19.6228C0.178223 19.7308 0.266616 19.8192 0.374651 19.8192H19.6247C19.7327 19.8192 19.8211 19.7308 19.8211 19.6228V18.7388C19.8211 18.3042 19.47 17.9531 19.0354 17.9531Z" fill="#009241" />
        </svg>
      </Button>
      <Modal centered className="edit-territories-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0 flex-column">
          <Modal.Title className="w-100">Edit Territory Campaigns</Modal.Title>
          <p className="text-black-50 w-100">Select new Campaigns</p>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={editTerritory}>
            {/* {users.length > 0 && (
              <React.Fragment>
                <Form.Label htmlFor='assignee' as='h6'>Assign Territory To</Form.Label>
                <InputGroup className='mb-3'>
                  <MultiSelect
                    onChange={onAssigneeSelect}
                    options={users}
                    //territory.assignees[users.findIndex(assignee => assignee.id === todo.assignee.id)]
                    defaultValue={assignees}
                    jsonValue
                    style={{
                      width: "100%"
                    }}
                  />
                </InputGroup>
              </React.Fragment>
            )} */}
            {allCampaigns.length > 0 && (
              <React.Fragment>
                <Form.Label htmlFor='campaigns' as='h6'>Assign Campaigns To</Form.Label>
                <InputGroup className='mb-3'>
                  <MultiSelect
                    onChange={onCampaignSelect}
                    options={allCampaigns}
                    defaultValue={campaigns}
                    jsonValue
                    style={{
                      width: "100%"
                    }}
                  />
                </InputGroup>
              </React.Fragment>
            )}
            <InputGroup className="mb-2 mt-3 d-flex justify-content-end">
              <Button className="rounded" variant='success' type="submit" disabled={!allowSubmit}>Update</Button>
            </InputGroup>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
