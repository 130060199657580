import React, { useEffect, useState } from 'react';
import { Col, Row, Container, Form, Button } from 'react-bootstrap';
import Header from "../../sections/configuration/Header";
import { getDocs, query, collection, where, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { auth, db, uP, storage } from '../../firebase';
import { PencilSquare } from 'react-bootstrap-icons';


const Configuration = ({ }) => {
  const [user, setUser] = useState({ id: '', email: '', fullName: '', phone: '' });
  const [enableUpdateUser, setEnableUpdateUser] = useState(false);
  const [enableUpdatepassword, setEnableUpdatepassword] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState('');
  const [setPercent] = useState(0);

  function handleChange(event) {
    handleUpload(event.target.files[0]);
  }

  const handleUpload = (file) => {
    if (!file) {
      alert("Please upload an image first!");
    }

    const storageRef = ref(storage, `/files/${file.name}`);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setAvatarUrl(url);
          const u = user;
          u.avatar = url;
          setUser(u);
        });
      }
    );
  };

  const getUser = async () => {
    let id = '';
    let email = '';
    let fullName = '';
    let phone = '';
    let avatar = '';
    const docRef = await getDocs(query(collection(db, "users"), where('email', '==', JSON.parse(localStorage.getItem("token")).email)));
    docRef.forEach((doc) => {
      id = doc.id;
      email = doc.data().email;
      fullName = doc.data().fullName;
      phone = doc.data().phone;
      avatar = doc.data().avatar;
      if (doc.data().avatar !== '') {
        setAvatarUrl(doc.data().avatar);
      }
    });
    setUser({
      id: id,
      email: email,
      fullName: fullName,
      phone: phone,
      avatar: avatar
    });
  }

  useEffect(() => {
    getUser();
    console.log(auth.currentUser)
  }, []);

  const updateUser = async (e) => {
    e.preventDefault();
    console.log(avatarUrl)
    await updateDoc(doc(db, "users", auth.currentUser.uid), {
      fullName: e.target.fullName.value,
      phone: e.target.phone.value,
      avatar: avatarUrl
    }).then((res) => {
      setUser({
        id: user.id,
        email: user.email,
        fullName: e.target.fullName.value,
        phone: e.target.phone.value,
        avatar: avatarUrl
      });
      setEnableUpdateUser(false);
    }).catch((err) => alert(err.message));
  }

  const updatePassword = async (e) => {
    e.preventDefault();
    console.log(e.target.password.value)
    await uP(auth.currentUser, e.target.password.value).then((res) => {
      console.log(res);
      setEnableUpdatepassword(false);
    }).catch((err) => alert(err.message));
    // await auth.currentUser.updatePassword(e.target.password.value).then((res) => {
    //   console.log(res);
    //   setEnableUpdatepassword(false);
    // }).catch((err) => alert(err.message));
  }

  return (
    <React.Fragment>
      <Header setEnableUpdateUser={setEnableUpdateUser} enableUpdateUser={enableUpdateUser} setEnableUpdatepassword={setEnableUpdatepassword} enableUpdatepassword={enableUpdatepassword} />
      <Container>
        <Row style={{ height: global.window.innerHeight }}>
          <Col style={{ borderRight: '0.5px solid #dee2e6', paddingTop: 30 }}>
            <Form onSubmit={updateUser}>
              <Row className="mb-3 text-center">
                <Form.Group as={Col}>
                  <Form.Label htmlFor='profile-image'>
                    <div className='rounded-circle text-center m-auto p-3 ' style={{ height: 100, width: 100, backgroundColor: '#e5e5e5', position: 'relative' }}>
                      {enableUpdateUser && (
                        <div className='update-profile-image-overlay rounded-circle'>
                          <PencilSquare />
                        </div>
                      )}
                      <img className='m-auto h-100' src={user.avatar !== '' ? user.avatar : "https://firebasestorage.googleapis.com/v0/b/d2d-new-fc399.appspot.com/o/default_user_image.png?alt=media&token=9364927b-e42b-4051-beaa-c253cc36e4e0"} />
                    </div>
                    <Form.Control style={{ display: 'none' }} onChange={handleChange} id="profile-image" name="profileimage" disabled={!enableUpdateUser} type="file" />
                  </Form.Label>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Name</Form.Label>
                  <Form.Control id="fullName" name="fullName" disabled={!enableUpdateUser} type="text" placeholder="Enter Fullname" defaultValue={user.fullName} />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control id="phone" name="phone" disabled={!enableUpdateUser} type="text" placeholder="phone" defaultValue={user.phone} />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} sm={6}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control id="email" disabled type="email" defaultValue={user.email} />
                </Form.Group>
              </Row>
              {enableUpdateUser && (
                <Row className="mb-3 justify-content-end">
                  <Col xs="auto">
                    <Button variant='success' type="submit" className="mb-2">
                      Submit
                    </Button>
                  </Col>
                </Row>
              )}

            </Form>
          </Col>
          <Col style={{ borderLeft: '0.5px solid #dee2e6', paddingTop: 30 }}>
            <Form onSubmit={updatePassword}>
              <Row className="mb-3">
                <Form.Group as={Col} sm={6}>
                  <Form.Label>Password</Form.Label>
                  <Form.Control id="password" name="password" disabled={!enableUpdatepassword} type="password" placeholder="******************" />
                </Form.Group>
              </Row>
              {enableUpdatepassword && (
                <Row className="mb-3 justify-content-end">
                  <Col xs="auto">
                    <Button variant='success' type="submit" className="mb-2">
                      Submit
                    </Button>
                  </Col>
                </Row>
              )}

            </Form>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default Configuration;