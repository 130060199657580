import { useEffect, useState, useMemo } from 'react';
import { Row, Col, Container, Form } from 'react-bootstrap';
import { collection, getDocs, where, query, updateDoc, doc } from "firebase/firestore";
import { db } from '../../firebase';
import Pagination from '../../components/pagination/pagination';

export default function PendingMemberListings() {
  const [users, setUsers] = useState([]);
  let PageSize = 10;

  const [currentPage, setCurrentPage] = useState(0);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return users.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, users]);

  async function fetchUsers() {
    const querySnapshot = await getDocs(query(collection(db, "users"), where('approvedByAdmin', '==', false)));
    let allUsers = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      allUsers.push({
        id: doc.id,
        fullName: doc.data().fullName,
        email: doc.data().email,
        avatar: doc.data().avatar !== undefined ? doc.data().avatar : '',
        role: doc.data().role || 'User',
        designation: doc.data().designation || ''
      });
      // console.log(doc.id, " => ", doc.data());
    });
    setCurrentPage(1);
    setUsers(allUsers);
    if(allUsers.length > 0) {
      document.getElementById("user-tabs-tabpane-pending").setAttribute("style", "height: "+ (window.innerHeight - document.getElementById("user-tabs-tabpane-pending").getBoundingClientRect().top - 70)+"px; overflow-y: scroll; overflow-x: hidden")
    }
    
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const setActiveOrInActive = async (id, value) => {
    await updateDoc(doc(db, "users", id), {
      approvedByAdmin: value
    })
    .then((res) => {
      fetchUsers();
    }).catch(() => {
      alert('Something went wrong please try again later');
    });
  }

  const colors = ['#009241', '#7B61FF', '#FCDE00'];
  return (
    <>
      {currentTableData.map((x, key) => {
        return (
          <Row key={key} className='border-bottom'>
            <Col>
              <Container>
                <Row className='pt-4 pb-4'>
                  <Col sm={1} className='align-self-center'>
                    {x.avatar !== '' && (
                      <img style={{
                        height: 50,
                        width: 50,
                        borderRadius: 100,
                      }} src={x.avatar} alt="avatar" />
                    )}
                    {x.avatar === '' && (
                      <div className='border'
                        style={{
                          height: 43,
                          width: 43,
                          borderRadius: 100,
                          padding: 10,
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          backgroundColor: colors[Math.floor(Math.random(0) * colors.length)]
                        }}>
                        {`${x.fullName.includes(' ') ? x.fullName.split(' ')[0].substring(0, 1) + x.fullName.split(' ')[1].substring(0, 1) : x.fullName.substring(0, 1) }`}
                      </div>
                    )}
                  </Col>
                  <Col sm={3} className='align-self-center'>
                    <p className='m-0'>{`${x.fullName.includes(' ') ? x.fullName.split(' ')[0] + ' ' + x.fullName.split(' ')[1] : x.fullName }`}</p>
                    <p className='m-0 text-black-50'>{x.designation}</p>
                  </Col>
                  <Col sm={5} className='align-self-center'>
                    {x.email}
                  </Col>
                  <Col sm={3} className='align-self-center text-end'>
                    <Form.Check
                      type="switch"
                      id={`custom-switch-${x.id}`}
                      label=""
                      defaultChecked={x.approvedByAdmin}
                      onChange={(e) => setActiveOrInActive(x.id, e.target.checked)}
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        );
      }
      )}
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={users.length}
        pageSize={PageSize}
        onPageChange={page => setCurrentPage(page)}
      />
      {currentTableData.length === 0 &&
        (
          <p className='text-center'>No Pending users Found</p>  
        )
      }
    </>
  );
}