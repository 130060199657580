import { Row, Col, Container, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import IconHeading from '../../components/icon-heading/IconHeading';
import { Lock, People } from 'react-bootstrap-icons';

Header.propTypes = {
  setEnableUpdateUser: PropTypes.func,
  setEnableUpdatepassword: PropTypes.func,
  enableUpdateUser: PropTypes.bool,
  enableUpdatepassword: PropTypes.bool
}

export default function Header({setEnableUpdateUser, enableUpdateUser, enableUpdatepassword, setEnableUpdatepassword}){
  return (
    <div id="configuration-height" className='d-flex border-bottom'>
      <Col>
        <Container>
          <Row>
            <Col style={{ borderRight: '0.5px solid #dee2e6' }} className='pt-4 pb-3' sm={6}>
              <Row>
                <Col>
                  <IconHeading icon={<People className="me-1 text-success" />} heading='Personal details' subHeading='Edit your personal information here' />
                </Col>
                <Col className='align-self-center text-end'>
                  <Button onClick={() => setEnableUpdateUser(!enableUpdateUser)} variant="success" className='me-2'>Edit Personal Details</Button>
                </Col>
              </Row>
            </Col>
            <Col style={{ borderLeft: '0.5px solid #dee2e6' }} className='pt-4 pb-3 text-end' sm={6}>
              <Row>
                <Col>
                  <IconHeading icon={<Lock className="me-1 text-success" />} heading='Security Details' subHeading='Edit and view your password' />
                </Col>
                <Col>
                  <Button onClick={() => setEnableUpdatepassword(!enableUpdatepassword)} variant="warning" className='me-2 text-body'>Change Password</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Col>
    </div>
  )
}
