import { Row, Col, Container, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { DefinedRange } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useState } from 'react';

Header.propTypes = {

}

export default function Header({ }) {

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: 'selection'
    }
  ]);
  const [buttonLabel, setButtonLabel] = useState('Filter');
  const [showRange, setShowRange] = useState(false);

  const onDateRangeSelect = (selection) => {
    setTimeout(() => {
      setButtonLabel(document.getElementsByClassName('rdrStaticRangeSelected')[0].innerText)
      setShowRange(false);
    }, 500);
  }

  return (
    <div className='d-flex border-bottom'>
      <Col>
        <Container>
          <Row>
            <Col className='pt-5 pb-3' sm={6}>

            </Col>
            <Col className='pt-5 pb-3 text-end' sm={6}>
              {/* <Button onClick={() => setShowRange(!showRange)} variant="light" className='me-2'>{buttonLabel}</Button> */}
              {showRange && (
                <DefinedRange
                  onChange={item => { setState([item.selection]); console.log(item); onDateRangeSelect(item)}}
                  ranges={state}
                />
              )}
            </Col>
          </Row>
        </Container>
      </Col>
    </div>
  )
}
