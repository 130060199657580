
import { useState, useEffect } from 'react';
import { Button, Offcanvas, Form, InputGroup, FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { collection, getDocs, doc, updateDoc, where, query } from "firebase/firestore";
import { db } from '../../firebase';
EditTodoSidebar.propTypes = {
  todo: PropTypes.object
};
function OffCanvasComponent({ fetchTodos, todo, ...props }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [assignees, setAssignees] = useState([]);

  async function fetchUsers() {
    const querySnapshot = await getDocs(query(collection(db, "users"), where('approvedByAdmin', '==', true), where('role', '==', 'User')));
    let allUsers = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      allUsers.push({
        id: doc.id,
        fullName: doc.data().fullName,
      });
      // console.log(doc.id, " => ", doc.data());
    });
    setAssignees(allUsers);
  }

  useEffect(() => {
    fetchUsers();
  }, []);

  const updateTodo = async (e) => {
    e.preventDefault();
    await updateDoc(doc(db, "todo-list", e.target.uid.value), {
      todo_description: e.target.description.value,
      todo_title: e.target.title.value,
      status: 1,
      due_date: e.target.dueDate.value,
      user: JSON.parse(e.target.assignee.value)
    }).then((res) => {
      fetchTodos();
      handleClose();
    }).catch((err) => alert(err.message));
  }

  return (
    <>
      <Button variant="transparent" onClick={handleShow} className='ml-0'>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.75568 15.8906C3.80479 15.8906 3.85389 15.8857 3.903 15.8783L8.03291 15.154C8.08202 15.1442 8.12867 15.1221 8.16304 15.0853L18.5713 4.677C18.5941 4.65428 18.6121 4.6273 18.6244 4.5976C18.6368 4.5679 18.6431 4.53606 18.6431 4.5039C18.6431 4.47174 18.6368 4.4399 18.6244 4.4102C18.6121 4.38049 18.5941 4.35351 18.5713 4.3308L14.4905 0.247536C14.4438 0.200884 14.3825 0.176331 14.3162 0.176331C14.2499 0.176331 14.1885 0.200884 14.1418 0.247536L3.73358 10.6558C3.69675 10.6926 3.67465 10.7368 3.66483 10.7859L2.9405 14.9158C2.91661 15.0474 2.92515 15.1828 2.96536 15.3102C3.00558 15.4377 3.07627 15.5535 3.1713 15.6475C3.33336 15.8047 3.53715 15.8906 3.75568 15.8906ZM5.41059 11.6085L14.3162 2.70535L16.1159 4.50513L7.21037 13.4083L5.02755 13.7937L5.41059 11.6085ZM19.0354 17.9531H0.963937C0.529339 17.9531 0.178223 18.3042 0.178223 18.7388V19.6228C0.178223 19.7308 0.266616 19.8192 0.374651 19.8192H19.6247C19.7327 19.8192 19.8211 19.7308 19.8211 19.6228V18.7388C19.8211 18.3042 19.47 17.9531 19.0354 17.9531Z" fill="#009241" />
        </svg>
      </Button>
      <Offcanvas className="rounded" show={show} onHide={handleClose} backdrop={false} {...props}>
        <Offcanvas.Header closeButton className='border-bottom' style={{ flexDirection: 'column' }}>
          <Offcanvas.Title>Edit: {todo.title}</Offcanvas.Title>
          <p className='text-black-50 m-0'>Enter fields here to Update todo</p>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={updateTodo}>
            <Form.Label htmlFor='email' as='h6'>Todo</Form.Label>
            <InputGroup className='mb-3'>
              <FormControl
                id='todo'
                name="title"
                defaultValue={todo.title}
                placeholder='Enter Todo'
                aria-label='todo'
                aria-describedby='Todo' />
            </InputGroup>
            <Form.Label htmlFor='t-description' as='h6'>Todo Description</Form.Label>
            <InputGroup className='mb-3'>
              <FormControl
                id='t-description'
                defaultValue={todo.description}
                type='textarea'
                name="description"
                as="textarea"
                placeholder='Enter Todo'
                aria-label='todo-Description'
                aria-describedby='todo-Description' />
            </InputGroup>
            <Form.Label htmlFor='assignee' as='h6'>Assign Todo</Form.Label>
            <InputGroup className='mb-3'>
              <Form.Select
                id='assignee'
                placeholder='Enter Todo'
                aria-label='todo-Description'
                aria-describedby='todo-Description'
                name="assignee"
                defaultValue={JSON.stringify(assignees[assignees.findIndex(assignee => assignee.id === todo.assignee.id)])}
              >
                <option disabled value="0">Select Assignee</option>
                {assignees.map((x, key) => (
                  <option key={key} value={JSON.stringify(x)}>{x.fullName}</option>
                ))}
              </Form.Select>
            </InputGroup>
            <Form.Label htmlFor='due-date' as='h6'>Due Date</Form.Label>
            <InputGroup className='mb-3'>
              <InputGroup.Text className='bg-transparent' id='due-date-label'><i className='text-success bi bi-calendar2'></i></InputGroup.Text>
              <FormControl
                id='due-date'
                type='date'
                name="dueDate"
                defaultValue={todo.dueDate}
                placeholder='Select Date'
                aria-label='select-date'
                aria-describedby='select-date' />
            </InputGroup>
            <input type="hidden" value={todo.id} name="uid" />
            <Button variant="success" type="submit" onClick={handleShow} className='me-2 w-100'>Update Todo</Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default function EditTodoSidebar({ todo, fetchTodos }) {
  return (
    <OffCanvasComponent placement="end" todo={todo} fetchTodos={fetchTodos} />
  );
}
