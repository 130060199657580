import { useState, useEffect } from "react";
import { Button, Modal, Form, InputGroup, FormControl, Col, Row } from 'react-bootstrap';
import { collection, addDoc, getDocs, where, query } from "firebase/firestore";
import MultiSelect from 'react-multiple-select-dropdown-lite';
import PropTypes from 'prop-types';
// import moment from 'moment';
import 'react-multiple-select-dropdown-lite/dist/index.css';
import { db, auth } from '../../firebase';
import { notifyCreatorAdmin, notifySuperAdmin } from "../../utils/notifications";

AddCampaignModal.propTypes = {
  fetchCampaigns: PropTypes.func,
};

export default function AddCampaignModal({ fetchCampaigns }) {
  const [show, setShow] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [users, setUsers] = useState([]);
  const [stepOne, setStepOne] = useState(false);
  const [stepTwo, setStepTwo] = useState(false);
  const [formStates, setFormStates] = useState({assignees: []});
  const [questions, setQuestions] = useState([
    {
      type: 'text',
      placeholder: 'Write question',
      label: 'Campaign Question',
      name: 'question_0',
      value: '',
      answer: {
        type: 'text',
        options: [],
        newOptions: false
      }
    }
  ]);
  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(query(collection(db, "users"), where('approvedByAdmin', '==', true), where('role', '==', 'User')));
      let allUsers = [];
      querySnapshot.forEach((doc) => {
        allUsers.push({
          value: doc.id,
          label: doc.data().fullName,
        });
      });
      setUsers(allUsers);
    }
    fetchUsers();
  }, []);
  const handleClose = () => {
    setShow(false)
    setStepOne(false);
    setStepTwo(false);
  };
  const handleSuccessClose = () => {
    setSuccessShow(false);
  };
  const handleShow = () => setShow(true);
  const handleSuccessShow = () => setSuccessShow(true);

  const onInputChange = (e) => {
    setFormStates(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
  }

  const dataPicker = (e) => {
    e.target.showPicker();
  }

  const calculateNoOfDays = (value, type) => {
    // if (formStates.start_date && formStates.end_date) {
    //   if (type === 'start') {
    //     document.getElementById("no-of-days").value = moment(formStates.end_date).diff(value, 'days') + ' days';
    //   } else if (type === 'end') {
    //     document.getElementById("no-of-days").value = moment(value).diff(formStates.start_date, 'days') + ' days';
    //   }
    // } else if (formStates.start_date) {
    //   document.getElementById("no-of-days").value = moment(value).diff(formStates.start_date, 'days') + ' days';
    // } else if (formStates.end_date) {
    //   document.getElementById("no-of-days").value = moment(formStates.end_date).diff(value, 'days') + ' days';
    // }
  }

  const onAnswerTypeChange = (e) => {
    const answerNumber = e.target.value.split('_')[1];
    const allQuestions = questions;
    if (e.target.value.split('_')[0] === 'Text Area' || e.target.value.split('_')[0] === 'Text box') {
      allQuestions[answerNumber].answer = {
        type: e.target.value.split('_')[0],
        name: `question_${answerNumber}_answer`,
        newOptions: false,
        options: []
      }
    } else if (e.target.value.split('_')[0] === 'Dropdowns') {
      allQuestions[answerNumber].answer = {
        type: e.target.value.split('_')[0],
        name: `question_${answerNumber}_answer`,
        newOptions: true,
        options: [
          ''
        ]
      }
    } else if (e.target.value.split('_')[0] === 'Checkboxes') {
      allQuestions[answerNumber].answer = {
        type: e.target.value.split('_')[0],
        name: `question_${answerNumber}_answer`,
        newOptions: true,
        options: [{
          value: {
            getData: '',
            status: false
          }
        }]
      }
    } else {
      allQuestions[answerNumber].answer = {
        type: e.target.value.split('_')[0],
        name: `question_${answerNumber}_answer`,
        newOptions: true,
        options: [
          {
            value: ''
          }
        ]
      }
    }
    setQuestions(JSON.parse(JSON.stringify(allQuestions)));
  }

  const addOptions = (index) => {
    const allQuestions = questions;
    if (allQuestions[index].answer.type === 'Dropdowns') {
      allQuestions[index].answer.options.push('');
    } else if (allQuestions[index].answer.type === 'Checkboxes') {
      allQuestions[index].answer.options.push({
        value: {
          getData: '',
          status: false
        }
      });
    } else {
      allQuestions[index].answer.options.push({
        value: ''
      });
    }
    setQuestions(JSON.parse(JSON.stringify(allQuestions)));
  }

  const addQuestion = () => {
    setQuestions(prevState => ([...prevState, {
      type: 'text',
      placeholder: 'Write question',
      label: 'Campaign Question',
      name: `question_${(questions.length + 1)}`,
      answer: {
        type: 'text'
      }
    }]));
  }

  const onOptionInputChange = (e, index, optionIndex) => {
    const allQuestions = questions;
    if (allQuestions[index].answer.type === 'Dropdowns') {
      allQuestions[index].answer.options[optionIndex] = e.target.value
    } else if (allQuestions[index].answer.type === 'Checkboxes') {
      allQuestions[index].answer.options[optionIndex].value.getData = e.target.value
    } else {
      allQuestions[index].answer.options[optionIndex].value = e.target.value
    }

    setQuestions(JSON.parse(JSON.stringify(allQuestions)));
  }

  const onAssigneeSelect = e => {
    var arr = [];
    e.forEach((x) => {
      arr.push(x.value);
    })
    // arr.push(JSON.parse(e));
    setFormStates(prevState => ({ ...prevState, ['assignees']: arr }));
  }

  const onQuestionInputChange = (e, index) => {
    const allQuestions = questions;
    allQuestions[index].value = e.target.value
    setQuestions(JSON.parse(JSON.stringify(allQuestions)));
  }

  const createCampagin = async () => {
    await addDoc(collection(db, "campaigns"), {
      details: formStates.c_details,
      name: formStates.c_name,
      date_range: {
        from: formStates.start_date,
        to: formStates.end_date
      },
      assignees: formStates.assignees,
      status: true,
      createdBy: auth.currentUser.uid
    }).then(async (res) => {
      await addDoc(collection(db, "campaign-questionnaire"), {
        questions: questions,
        campaignId: res.id
      }).then(async (response) => {
        setFormStates({});
        setQuestions([{
          type: 'text',
          placeholder: 'Write question',
          label: 'Campaign Question',
          name: 'question_0',
          value: '',
          answer: {
            type: 'text',
            options: [],
            newOptions: false
          }
        }]);
        fetchCampaigns();
        handleClose();
        await notifySuperAdmin('New Campaign Has Been Created', `${formStates.c_name} Has Been Created`);
        await notifyCreatorAdmin('New Campaign Has Been Created', `${formStates.c_name} Has Been Created`);
        handleSuccessShow(true);
      }).catch((err) => alert(err.message));
    }).catch((err) => alert(err.message));
  }

  return (
    <>
      <Button variant="success" onClick={handleShow}>+ Create new Campaign</Button>
      <Modal backdropClassName="campaign-create-modal-backdrop" className="add-campaign create-camp" centered size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-0 flex-column">
          <Row style={{ height: 100, margin: 'auto', width: '100%' }} className="campaign-create-form-step-guide bg-white">
            <Col>
              <Row className="h-100">
                <Col sm={2} className="align-self-center w-auto">
                  <div className="steps-completed" style={{ backgroundColor: (stepOne ? '#009241' : '') }}>
                    {stepOne && (
                      <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 8.94737L6.03846 13L17 2" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    )}
                  </div>
                </Col>
                <Col className="align-self-center">
                  <h6 className="m-0 text-start text-black text-bolder">Campaign Details</h6>
                  <p className="m-0 text-start text-muted secondary-text">Enter basic campaign details</p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="h-100">
                <Col className="align-self-center">
                  <svg width="250" height="2" viewBox="0 0 250 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="0.199951" y1="1" x2="250" y2="1" stroke="#707070" strokeOpacity="0.5" strokeWidth="2" />
                  </svg>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="h-100">
                <Col sm={2} className="align-self-center w-auto">
                  <div className="steps-completed" style={{ backgroundColor: (stepTwo ? '#009241' : '') }}>
                    {stepTwo && (
                      <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 8.94737L6.03846 13L17 2" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    )}
                  </div>
                </Col>
                <Col className="align-self-center">
                  <h6 className="m-0 text-start text-black text-bolder">Campaign Questionnaire</h6>
                  <p className="m-0 text-start text-muted secondary-text">Add questions to campaigns</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Modal.Title className="w-100 mt-4">Create New Campaign</Modal.Title>
          <p className="text-black-50 w-100">Enter details here to create new campaign</p>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => { e.preventDefault() }}>
            {!stepOne && (
              <>
                <Form.Label htmlFor="c_name" as="h6">Campaign Name</Form.Label>
                <InputGroup className="mb-2">
                  <FormControl
                    id="c_name"
                    name="c_name"
                    type="text"
                    placeholder="Enter campaign name"
                    aria-label="c_name"
                    aria-describedby="c_name"
                    onChange={(e) => {
                      onInputChange(e)
                    }}
                  />
                </InputGroup>
                <Form.Label className="mt-3" htmlFor="c_details" as="h6">Campaign Details</Form.Label>
                <InputGroup className="mb-2">
                  <FormControl
                    id="c_details"
                    name="c_details"
                    type="text"
                    placeholder="Enter campaign details"
                    as="textarea"
                    rows={3}
                    aria-label="c_details"
                    aria-describedby="c_details"
                    onChange={(e) => {
                      onInputChange(e)
                    }}
                  />
                </InputGroup>
                <Row className="mt-3">
                  <Form.Group as={Col}>
                    <Form.Label htmlFor="start-date" as="h6">Start Date</Form.Label>
                    <FormControl
                      id='start-date'
                      name="start_date"
                      type='date'
                      onClick={dataPicker}
                      placeholder='Select Date'
                      aria-label='select-date'
                      aria-describedby='select-date'
                      onChange={(e) => { onInputChange(e); calculateNoOfDays(e.target.value, 'start') }}
                    />
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label htmlFor="end-date" as="h6">End Date</Form.Label>
                    <FormControl
                      id='end-date'
                      name="end_date"
                      type='date'
                      onClick={dataPicker}
                      placeholder='Select Date'
                      aria-label='select-date'
                      aria-describedby='select-date'
                      onChange={(e) => { onInputChange(e); calculateNoOfDays(e.target.value, 'end') }}
                    />
                  </Form.Group>

                  {/* <Form.Group as={Col}>
                    <Form.Label htmlFor="no-of-days" as="h6">Milestones</Form.Label>
                    <Form.Control
                      disabled
                      defaultValue={0}
                      id="no-of-days"
                    />
                  </Form.Group> */}
                </Row>
                <Row>
                  <Form.Group as={Col}>
                    <Form.Label htmlFor="end-date" as="h6">Assign Campaigns To</Form.Label>
                    <MultiSelect
                      onChange={onAssigneeSelect}
                      options={users}
                      jsonValue
                      style={{
                        width: "100%"
                      }}
                    />
                  </Form.Group>
                </Row>
              </>
            )}
            {stepOne && !stepTwo && (
              <>
                {questions.map((x, key) => {
                  return (
                    <Row key={key} className="border-bottom pb-2 mb-3">
                      <Col>
                        <Form.Label htmlFor={`${x.name}`} as="h6">{x.label}</Form.Label>
                        <InputGroup className="mb-3">
                          <FormControl
                            id={`${x.name}`}
                            name={`${x.name}`}
                            type={x.type}
                            placeholder={x.placeholder}
                            aria-label={`${x.name}`}
                            aria-describedby={`${x.name}`}
                            onChange={(e) => {
                              onQuestionInputChange(e, key)
                            }}
                          />
                        </InputGroup>
                        <Form.Label htmlFor={`answer_type_${key}`} as="h6">Campaign Answer Type</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Select onChange={(e) => onAnswerTypeChange(e)} aria-label="Campaign Answer Type">
                            <option>Select answer type</option>
                            {['Text box', 'Checkboxes', 'Radio buttons', 'Dropdowns', 'Text Area'].map((type, typeKey) => {
                              return (
                                <option key={typeKey} value={`${type}_${key}`}>{type}</option>
                              );
                            })}
                          </Form.Select>
                        </InputGroup>
                        {x.answer.newOptions && (
                          <>
                            <Row>
                              {x.answer.options.map((option, optionKey) => {
                                return (
                                  <Col sm={3} key={optionKey}>
                                    <InputGroup className="mb-2">
                                      <FormControl
                                        id={`answer_option_${key}`}
                                        name={`answer_option_${key}`}
                                        type="text"
                                        placeholder="Enter Option value"
                                        aria-label={`answer_option_${key}`}
                                        aria-describedby={`answer_option_${key}`}
                                        onChange={(e) => {
                                          onOptionInputChange(e, key, optionKey);
                                        }}
                                      />
                                    </InputGroup>
                                  </Col>
                                );
                              })}
                            </Row>
                            <Row>
                              <Col>
                                <Button className="btn add-option-font" onClick={() => { addOptions(key) }}>+ &nbsp; Add Option</Button>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                  )
                })}
              </>
            )}
          </Form>
          <Row className="justify-content-end">
            <Col className="mt-3 text-end" sm={4}>
              {!stepOne && (
                <Button className="btn btn-primary btn-success w-100" onClick={() => { setStepOne(true) }}>Next</Button>
              )}
              {stepOne && !stepTwo && (
                <Button className="btn btn-primary btn-success w-100" onClick={() => { createCampagin() }}>Create Campaign</Button>
              )}
            </Col>
          </Row>
          {stepOne && !stepTwo && (
            <Button className="btn btn-primary btn-success add-question-button" onClick={() => { addQuestion() }}>Add Question</Button>
          )}
        </Modal.Body>
      </Modal>
      <Modal backdropClassName="campaign-create-modal-backdrop" className="" centered size="md" show={successShow} onHide={handleClose}>
        <Modal.Header closeButton className="border-0 flex-column">
          <Modal.Title className="w-100">Campaign Created Successfully</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="pt-3 pb-4">
            <Col>
              <div className="steps-completed" style={{ backgroundColor: '#009241', margin: '0 auto' }}>
                <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2 8.94737L6.03846 13L17 2" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-end">
            <Col className="mt-3 text-end">
              <Button className="btn btn-secondary w-100" onClick={() => { handleSuccessClose() }}>Go to Campaigns</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
